(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.au.W === region.aJ.W)
	{
		return 'on line ' + region.au.W;
	}
	return 'on lines ' + region.au.W + ' through ' + region.aJ.W;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bZ,
		impl.ct,
		impl.cp,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		H: func(record.H),
		av: record.av,
		ar: record.ar
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.H;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.av;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.ar) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bZ,
		impl.ct,
		impl.cp,
		function(sendToApp, initialModel) {
			var view = impl.cu;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bZ,
		impl.ct,
		impl.cp,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.at && impl.at(sendToApp)
			var view = impl.cu;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.u);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.bx) && (_VirtualDom_doc.title = title = doc.bx);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.cd;
	var onUrlRequest = impl.ce;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		at: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.ba === next.ba
							&& curr.aW === next.aW
							&& curr.a6.a === next.a6.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		bZ: function(flags)
		{
			return A3(impl.bZ, flags, _Browser_getUrl(), key);
		},
		cu: impl.cu,
		ct: impl.ct,
		cp: impl.cp
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { bW: 'hidden', bL: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { bW: 'mozHidden', bL: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { bW: 'msHidden', bL: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { bW: 'webkitHidden', bL: 'webkitvisibilitychange' }
		: { bW: 'hidden', bL: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bn: _Browser_getScene(),
		bB: {
			bD: _Browser_window.pageXOffset,
			bE: _Browser_window.pageYOffset,
			bC: _Browser_doc.documentElement.clientWidth,
			aT: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		bC: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		aT: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bn: {
				bC: node.scrollWidth,
				aT: node.scrollHeight
			},
			bB: {
				bD: node.scrollLeft,
				bE: node.scrollTop,
				bC: node.clientWidth,
				aT: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bn: _Browser_getScene(),
			bB: {
				bD: x,
				bE: y,
				bC: _Browser_doc.documentElement.clientWidth,
				aT: _Browser_doc.documentElement.clientHeight
			},
			bQ: {
				bD: x + rect.left,
				bE: y + rect.top,
				bC: rect.width,
				aT: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.b3) { flags += 'm'; }
	if (options.bK) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $author$project$Main$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.h) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.l),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.l);
		} else {
			var treeLen = builder.h * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.m) : builder.m;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.h);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.l) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.l);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{m: nodeList, h: (len / $elm$core$Array$branchFactor) | 0, l: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {aP: fragment, aW: host, ch: path, a6: port_, ba: protocol, bb: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Main$RouteMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Elm$Route$ContactModel = function (a) {
	return {$: 11, a: a};
};
var $author$project$Elm$Route$CyclingDistanceModel = function (a) {
	return {$: 7, a: a};
};
var $author$project$Elm$Route$CyclingDistanceMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Elm$Route$CyclingSpeedModel = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Route$CyclingSpeedMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Elm$Route$CyclingTimeModel = function (a) {
	return {$: 6, a: a};
};
var $author$project$Elm$Route$CyclingTimeMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Elm$Route$Home = 1;
var $author$project$Elm$Route$HomeModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Route$NoOp = {$: 0};
var $author$project$Elm$Route$NotFound = 0;
var $author$project$Elm$Route$NotFoundModel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Route$RunningDistanceModel = function (a) {
	return {$: 4, a: a};
};
var $author$project$Elm$Route$RunningDistanceMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Elm$Route$RunningPaceModel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Route$RunningPaceMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Route$RunningTimeModel = function (a) {
	return {$: 3, a: a};
};
var $author$project$Elm$Route$RunningTimeMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Elm$Route$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Route$ShowContent = {$: 17};
var $author$project$Elm$Route$SwimmingDistanceModel = function (a) {
	return {$: 10, a: a};
};
var $author$project$Elm$Route$SwimmingDistanceMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Elm$Route$SwimmingPaceModel = function (a) {
	return {$: 8, a: a};
};
var $author$project$Elm$Route$SwimmingPaceMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Elm$Route$SwimmingTimeModel = function (a) {
	return {$: 9, a: a};
};
var $author$project$Elm$Route$SwimmingTimeMsg = function (a) {
	return {$: 12, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Elm$Route$getPageDescription = function (route) {
	var _default = 'Speed Matters app provides various calculators for running, cycling swimming and triathlon. Easily calculate your distance, speed, time or pace.';
	switch (route) {
		case 0:
			return _default;
		case 1:
			return _default;
		case 2:
			return 'Calculate running pace. Determine your performance on the half marathon, marathon or any different running distance.';
		case 3:
			return 'Calculate running time. Determine your performance on the half marathon, marathon or any different running distance.';
		case 4:
			return 'Calculate running distance. Determine your performance on the half marathon, marathon or any different running distance.';
		case 5:
			return 'Calculate cycling speed. Determine your performance on the next training ride or race.';
		case 6:
			return 'Calculate cycling time. Determine your performance on the next training ride or race.';
		case 7:
			return 'Calculate cycling distance. Determine your performance on the next training ride or race.';
		case 8:
			return 'Calculate swimming pace. Determine your performance on the next training session in the pool or any open water event.';
		case 9:
			return 'Calculate swimming time. Determine your performance on the next training session in the pool or any open water event.';
		case 10:
			return 'Calculate swimming distance. Determine your performance on the next training session in the pool or any open water event.';
		default:
			return _default;
	}
};
var $author$project$Elm$Page$Contact$init = '';
var $author$project$Elm$Service$Unit$Distance = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingDistance$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Service$Unit$Kilometer = 0;
var $author$project$Elm$Service$Unit$KilometersPerHour = 0;
var $author$project$Elm$Page$CyclingDistance$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Service$Unit$Speed = function (a) {
	return {$: 3, a: a};
};
var $author$project$Elm$Service$Unit$Time = {$: 1};
var $author$project$Elm$Element$Input$init = function (unit) {
	return {an: true, t: unit, E: ''};
};
var $author$project$Elm$Element$Result$initialValue = '...';
var $author$project$Elm$Element$Result$init = function (unit) {
	return {t: unit, E: $author$project$Elm$Element$Result$initialValue};
};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Elm$Page$CyclingDistance$init = _Utils_Tuple2(
	{
		aZ: false,
		bj: $author$project$Elm$Element$Result$init(
			$author$project$Elm$Service$Unit$Distance(0)),
		j: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Speed(0)),
		n: $author$project$Elm$Element$Input$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$CyclingDistance$Self($author$project$Elm$Page$CyclingDistance$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$CyclingSpeed$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Page$CyclingSpeed$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$init = _Utils_Tuple2(
	{
		g: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Distance(0)),
		aZ: false,
		bj: $author$project$Elm$Element$Result$init(
			$author$project$Elm$Service$Unit$Speed(0)),
		n: $author$project$Elm$Element$Input$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$CyclingSpeed$Self($author$project$Elm$Page$CyclingSpeed$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$CyclingTime$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Page$CyclingTime$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingTime$init = _Utils_Tuple2(
	{
		g: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Distance(0)),
		aZ: false,
		bj: $author$project$Elm$Element$Result$init($author$project$Elm$Service$Unit$Time),
		j: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Speed(0))
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$CyclingTime$Self($author$project$Elm$Page$CyclingTime$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$Home$init = {};
var $author$project$Elm$Page$NotFound$init = {};
var $author$project$Elm$Page$RunningDistance$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Service$Unit$Pace = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Service$Unit$PerKilometer = 0;
var $author$project$Elm$Page$RunningDistance$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningDistance$init = _Utils_Tuple2(
	{
		aZ: false,
		i: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Pace(0)),
		bj: $author$project$Elm$Element$Result$init(
			$author$project$Elm$Service$Unit$Distance(0)),
		n: $author$project$Elm$Element$Input$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$RunningDistance$Self($author$project$Elm$Page$RunningDistance$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$RunningPace$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Page$RunningPace$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningPace$init = _Utils_Tuple2(
	{
		g: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Distance(0)),
		aZ: false,
		bj: $author$project$Elm$Element$Result$init(
			$author$project$Elm$Service$Unit$Pace(0)),
		n: $author$project$Elm$Element$Input$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$RunningPace$Self($author$project$Elm$Page$RunningPace$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$RunningTime$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Page$RunningTime$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningTime$init = _Utils_Tuple2(
	{
		g: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Distance(0)),
		aZ: false,
		i: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Pace(0)),
		bj: $author$project$Elm$Element$Result$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$RunningTime$Self($author$project$Elm$Page$RunningTime$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$SwimmingDistance$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Service$Unit$Meter = 1;
var $author$project$Elm$Service$Unit$Per100Meters = 2;
var $author$project$Elm$Page$SwimmingDistance$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$init = _Utils_Tuple2(
	{
		aZ: false,
		i: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Pace(2)),
		bj: $author$project$Elm$Element$Result$init(
			$author$project$Elm$Service$Unit$Distance(1)),
		n: $author$project$Elm$Element$Input$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$SwimmingDistance$Self($author$project$Elm$Page$SwimmingDistance$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$SwimmingPace$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Page$SwimmingPace$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingPace$init = _Utils_Tuple2(
	{
		g: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Distance(1)),
		aZ: false,
		bj: $author$project$Elm$Element$Result$init(
			$author$project$Elm$Service$Unit$Pace(2)),
		n: $author$project$Elm$Element$Input$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$SwimmingPace$Self($author$project$Elm$Page$SwimmingPace$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $author$project$Elm$Page$SwimmingTime$GetFromLocalStorage = {$: 6};
var $author$project$Elm$Page$SwimmingTime$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingTime$init = _Utils_Tuple2(
	{
		g: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Distance(1)),
		aZ: false,
		i: $author$project$Elm$Element$Input$init(
			$author$project$Elm$Service$Unit$Pace(2)),
		bj: $author$project$Elm$Element$Result$init($author$project$Elm$Service$Unit$Time)
	},
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$Elm$Page$SwimmingTime$Self($author$project$Elm$Page$SwimmingTime$GetFromLocalStorage);
		},
		$elm$core$Process$sleep(100)));
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Elm$Port$pageInitialized = _Platform_outgoingPort(
	'pageInitialized',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {K: frag, L: params, J: unvisited, E: value, O: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.J;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.E);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.E);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.ch),
					$elm$url$Url$Parser$prepareQuery(url.bb),
					url.aP,
					$elm$core$Basics$identity)));
	});
var $author$project$Elm$Route$Contact = 11;
var $author$project$Elm$Route$CyclingDistance = 7;
var $author$project$Elm$Route$CyclingSpeed = 5;
var $author$project$Elm$Route$CyclingTime = 6;
var $author$project$Elm$Route$RunningDistance = 4;
var $author$project$Elm$Route$RunningPace = 2;
var $author$project$Elm$Route$RunningTime = 3;
var $author$project$Elm$Route$SwimmingDistance = 10;
var $author$project$Elm$Route$SwimmingPace = 8;
var $author$project$Elm$Route$SwimmingTime = 9;
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.O;
		var unvisited = _v0.J;
		var params = _v0.L;
		var frag = _v0.K;
		var value = _v0.E;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.O;
			var unvisited = _v1.J;
			var params = _v1.L;
			var frag = _v1.K;
			var value = _v1.E;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.O;
		var unvisited = _v0.J;
		var params = _v0.L;
		var frag = _v0.K;
		var value = _v0.E;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Elm$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, 1, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			1,
			$elm$url$Url$Parser$s('index.html')),
			A2(
			$elm$url$Url$Parser$map,
			2,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('running'),
				$elm$url$Url$Parser$s('pace'))),
			A2(
			$elm$url$Url$Parser$map,
			3,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('running'),
				$elm$url$Url$Parser$s('time'))),
			A2(
			$elm$url$Url$Parser$map,
			4,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('running'),
				$elm$url$Url$Parser$s('distance'))),
			A2(
			$elm$url$Url$Parser$map,
			5,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cycling'),
				$elm$url$Url$Parser$s('speed'))),
			A2(
			$elm$url$Url$Parser$map,
			6,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cycling'),
				$elm$url$Url$Parser$s('time'))),
			A2(
			$elm$url$Url$Parser$map,
			7,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cycling'),
				$elm$url$Url$Parser$s('distance'))),
			A2(
			$elm$url$Url$Parser$map,
			8,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('swimming'),
				$elm$url$Url$Parser$s('pace'))),
			A2(
			$elm$url$Url$Parser$map,
			9,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('swimming'),
				$elm$url$Url$Parser$s('time'))),
			A2(
			$elm$url$Url$Parser$map,
			10,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('swimming'),
				$elm$url$Url$Parser$s('distance'))),
			A2(
			$elm$url$Url$Parser$map,
			11,
			$elm$url$Url$Parser$s('contact'))
		]));
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $author$project$Elm$Util$Dom$scrollTop = F2(
	function (id, tagger) {
		return A2(
			$elm$core$Task$attempt,
			function (_v1) {
				return tagger;
			},
			A2(
				$elm$core$Task$andThen,
				function (_v0) {
					return A3($elm$browser$Browser$Dom$setViewportOf, id, 0, 0);
				},
				$elm$browser$Browser$Dom$getViewportOf(id)));
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Elm$Route$init = function (url) {
	var route = A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm$url$Url$Parser$parse, $author$project$Elm$Route$parser, url));
	var _v0 = $author$project$Elm$Page$SwimmingTime$init;
	var swimmingTimeModel = _v0.a;
	var swimmingTimeCmd = _v0.b;
	var _v1 = $author$project$Elm$Page$SwimmingPace$init;
	var swimmingPaceModel = _v1.a;
	var swimmingPaceCmd = _v1.b;
	var _v2 = $author$project$Elm$Page$SwimmingDistance$init;
	var swimmingDistanceModel = _v2.a;
	var swimmingDistanceCmd = _v2.b;
	var _v3 = $author$project$Elm$Page$RunningTime$init;
	var runningTimeModel = _v3.a;
	var runningTimeCmd = _v3.b;
	var _v4 = $author$project$Elm$Page$RunningPace$init;
	var runningPaceModel = _v4.a;
	var runningPaceCmd = _v4.b;
	var _v5 = $author$project$Elm$Page$RunningDistance$init;
	var runningDistanceModel = _v5.a;
	var runningDistanceCmd = _v5.b;
	var _v6 = $author$project$Elm$Page$CyclingTime$init;
	var cyclingTimeModel = _v6.a;
	var cyclingTimeCmd = _v6.b;
	var _v7 = $author$project$Elm$Page$CyclingSpeed$init;
	var cyclingSpeedModel = _v7.a;
	var cyclingSpeedCmd = _v7.b;
	var _v8 = $author$project$Elm$Page$CyclingDistance$init;
	var cyclingDistanceModel = _v8.a;
	var cyclingDistanceCmd = _v8.b;
	return _Utils_Tuple2(
		{
			d: function () {
				switch (route) {
					case 0:
						return $author$project$Elm$Route$NotFoundModel($author$project$Elm$Page$NotFound$init);
					case 1:
						return $author$project$Elm$Route$HomeModel($author$project$Elm$Page$Home$init);
					case 2:
						return $author$project$Elm$Route$RunningPaceModel(runningPaceModel);
					case 3:
						return $author$project$Elm$Route$RunningTimeModel(runningTimeModel);
					case 4:
						return $author$project$Elm$Route$RunningDistanceModel(runningDistanceModel);
					case 5:
						return $author$project$Elm$Route$CyclingSpeedModel(cyclingSpeedModel);
					case 6:
						return $author$project$Elm$Route$CyclingTimeModel(cyclingTimeModel);
					case 7:
						return $author$project$Elm$Route$CyclingDistanceModel(cyclingDistanceModel);
					case 8:
						return $author$project$Elm$Route$SwimmingPaceModel(swimmingPaceModel);
					case 9:
						return $author$project$Elm$Route$SwimmingTimeModel(swimmingTimeModel);
					case 10:
						return $author$project$Elm$Route$SwimmingDistanceModel(swimmingDistanceModel);
					default:
						return $author$project$Elm$Route$ContactModel($author$project$Elm$Page$Contact$init);
				}
			}(),
			X: false,
			as: route !== 1,
			R: _Utils_Tuple2('false', ''),
			ak: false
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Task$perform,
					function (_v10) {
						return $author$project$Elm$Route$Self($author$project$Elm$Route$ShowContent);
					},
					$elm$core$Process$sleep(100)),
					A2(
					$author$project$Elm$Util$Dom$scrollTop,
					'content',
					$author$project$Elm$Route$Self($author$project$Elm$Route$NoOp)),
					$author$project$Elm$Port$pageInitialized(
					_Utils_Tuple2(
						url.ch,
						$author$project$Elm$Route$getPageDescription(route))),
					function () {
					switch (route) {
						case 2:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningPaceMsg),
								runningPaceCmd);
						case 3:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningTimeMsg),
								runningTimeCmd);
						case 4:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningDistanceMsg),
								runningDistanceCmd);
						case 5:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingSpeedMsg),
								cyclingSpeedCmd);
						case 6:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingTimeMsg),
								cyclingTimeCmd);
						case 7:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingDistanceMsg),
								cyclingDistanceCmd);
						case 8:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingPaceMsg),
								swimmingPaceCmd);
						case 9:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingTimeMsg),
								swimmingTimeCmd);
						case 10:
							return A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingDistanceMsg),
								swimmingDistanceCmd);
						default:
							return $elm$core$Platform$Cmd$none;
					}
				}()
				])));
};
var $author$project$Main$init = F3(
	function (_v0, url, key) {
		var _v1 = $author$project$Elm$Route$init(url);
		var routeModel = _v1.a;
		var routeCmd = _v1.b;
		return _Utils_Tuple2(
			{ag: key, N: routeModel, ay: url},
			A2($elm$core$Platform$Cmd$map, $author$project$Main$RouteMsg, routeCmd));
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Elm$Page$CyclingDistance$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Elm$Port$responseFromLocalStorage = _Platform_incomingPort('responseFromLocalStorage', $elm$json$Json$Decode$value);
var $author$project$Elm$Page$CyclingDistance$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Self, $author$project$Elm$Page$CyclingDistance$LocalStorageResponse));
};
var $author$project$Elm$Page$CyclingSpeed$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Self, $author$project$Elm$Page$CyclingSpeed$LocalStorageResponse));
};
var $author$project$Elm$Page$CyclingTime$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$CyclingTime$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Self, $author$project$Elm$Page$CyclingTime$LocalStorageResponse));
};
var $author$project$Elm$Page$RunningDistance$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$RunningDistance$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Self, $author$project$Elm$Page$RunningDistance$LocalStorageResponse));
};
var $author$project$Elm$Page$RunningPace$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$RunningPace$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Self, $author$project$Elm$Page$RunningPace$LocalStorageResponse));
};
var $author$project$Elm$Page$RunningTime$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$RunningTime$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Self, $author$project$Elm$Page$RunningTime$LocalStorageResponse));
};
var $author$project$Elm$Page$SwimmingDistance$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Self, $author$project$Elm$Page$SwimmingDistance$LocalStorageResponse));
};
var $author$project$Elm$Page$SwimmingPace$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$SwimmingPace$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Self, $author$project$Elm$Page$SwimmingPace$LocalStorageResponse));
};
var $author$project$Elm$Page$SwimmingTime$LocalStorageResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Elm$Page$SwimmingTime$subscriptions = function (_v0) {
	return $author$project$Elm$Port$responseFromLocalStorage(
		A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Self, $author$project$Elm$Page$SwimmingTime$LocalStorageResponse));
};
var $author$project$Elm$Route$subscriptions = function (model) {
	var _v0 = model.d;
	switch (_v0.$) {
		case 2:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningPaceMsg),
				$author$project$Elm$Page$RunningPace$subscriptions(content));
		case 3:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningTimeMsg),
				$author$project$Elm$Page$RunningTime$subscriptions(content));
		case 4:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningDistanceMsg),
				$author$project$Elm$Page$RunningDistance$subscriptions(content));
		case 5:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingSpeedMsg),
				$author$project$Elm$Page$CyclingSpeed$subscriptions(content));
		case 6:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingTimeMsg),
				$author$project$Elm$Page$CyclingTime$subscriptions(content));
		case 7:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingDistanceMsg),
				$author$project$Elm$Page$CyclingDistance$subscriptions(content));
		case 8:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingPaceMsg),
				$author$project$Elm$Page$SwimmingPace$subscriptions(content));
		case 9:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingTimeMsg),
				$author$project$Elm$Page$SwimmingTime$subscriptions(content));
		case 10:
			var content = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingDistanceMsg),
				$author$project$Elm$Page$SwimmingDistance$subscriptions(content));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Main$subscriptions = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$Main$RouteMsg,
		$author$project$Elm$Route$subscriptions(model.N));
};
var $author$project$Main$ChangePage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Elm$Util$Cmd$fire = function (msg) {
	return A2(
		$elm$core$Task$perform,
		function (a) {
			return a;
		},
		$elm$core$Task$succeed(msg));
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.ba;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.aP,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.bb,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.a6,
					_Utils_ap(http, url.aW)),
				url.ch)));
};
var $author$project$Elm$Route$ContactMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Elm$Route$HideSnackbar = {$: 16};
var $author$project$Elm$Route$HomeMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Elm$Route$ShowSnackbar = function (a) {
	return {$: 15, a: a};
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Elm$Page$Contact$update = F2(
	function (msg, _v0) {
		return _Utils_Tuple2('spdmttrs@gmail.com', $elm$core$Platform$Cmd$none);
	});
var $author$project$Elm$Page$CyclingDistance$CalculateDistance = {$: 3};
var $author$project$Elm$Page$CyclingDistance$HideSnackbar = {$: 1};
var $author$project$Elm$Page$CyclingDistance$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$CyclingDistance$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$CyclingDistance$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingDistance$SpeedInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$CyclingDistance$TimeInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingDistance$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingDistance$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$round = _Basics_round;
var $author$project$Elm$Service$Calculator$roundTo = F2(
	function (to, num) {
		return $elm$core$Basics$round(num * to) / to;
	});
var $author$project$Elm$Service$Calculator$distanceBySpeed = F2(
	function (t, s) {
		return A2($author$project$Elm$Service$Calculator$roundTo, 1000, t * s);
	});
var $author$project$Elm$Service$Calculator$kmPerHToMiPerH = function (kmh) {
	return A2($author$project$Elm$Service$Calculator$roundTo, 10, kmh / 1.60934);
};
var $author$project$Elm$Service$Calculator$miPerHToKmPerH = function (mph) {
	return A2($author$project$Elm$Service$Calculator$roundTo, 10, mph * 1.60934);
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {bY: index, b$: match, cc: number, co: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{bK: false, b3: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $author$project$Elm$Service$Validator$isValueValid = F2(
	function (regex, value) {
		return $elm$regex$Regex$contains(
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString(regex)))(value);
	});
var $author$project$Elm$Service$Validator$timeRegex = '^(\\d+):[0-5][0-9]:[0-5][0-9]$';
var $author$project$Elm$Service$Validator$isTime = $author$project$Elm$Service$Validator$isValueValid($author$project$Elm$Service$Validator$timeRegex);
var $author$project$Elm$Service$Calculator$minToH = function (min) {
	return min / 60;
};
var $author$project$Elm$Service$Calculator$secToH = function (sec) {
	return sec / 3600;
};
var $author$project$Elm$Service$Converter$timeToHour = function (value) {
	if ($author$project$Elm$Service$Validator$isTime(value)) {
		var _v0 = A2(
			$elm$core$List$map,
			$elm$core$String$toInt,
			A2($elm$core$String$split, ':', value));
		if ((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && (!_v0.b.b.a.$)) && (!_v0.b.b.b.b)) {
			var h = _v0.a.a;
			var _v1 = _v0.b;
			var min = _v1.a.a;
			var _v2 = _v1.b;
			var sec = _v2.a.a;
			return $elm$core$Maybe$Just(
				(h + $author$project$Elm$Service$Calculator$minToH(min)) + $author$project$Elm$Service$Calculator$secToH(sec));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Elm$Service$Validator$unhandledException = 'Something went wrong. Please contact us';
var $author$project$Elm$Page$CyclingDistance$calculate = F3(
	function (time, speed, result) {
		var _v0 = _Utils_Tuple2(
			$author$project$Elm$Service$Converter$timeToHour(time.E),
			$elm$core$String$toFloat(speed.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var t = _v0.a.a;
			var s = _v0.b.a;
			var _v1 = _Utils_Tuple2(result.t, speed.t);
			_v1$4:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 3)) {
					if (_v1.b.a === 1) {
						switch (_v1.a.a) {
							case 2:
								var _v4 = _v1.a.a;
								var _v5 = _v1.b.a;
								return $author$project$Elm$Page$CyclingDistance$CalculationSuccess(
									A2($author$project$Elm$Service$Calculator$distanceBySpeed, t, s));
							case 0:
								var _v6 = _v1.a.a;
								var _v7 = _v1.b.a;
								return $author$project$Elm$Page$CyclingDistance$CalculationSuccess(
									A2(
										$author$project$Elm$Service$Calculator$distanceBySpeed,
										t,
										$author$project$Elm$Service$Calculator$miPerHToKmPerH(s)));
							default:
								break _v1$4;
						}
					} else {
						switch (_v1.a.a) {
							case 0:
								var _v2 = _v1.a.a;
								var _v3 = _v1.b.a;
								return $author$project$Elm$Page$CyclingDistance$CalculationSuccess(
									A2($author$project$Elm$Service$Calculator$distanceBySpeed, t, s));
							case 2:
								var _v8 = _v1.a.a;
								var _v9 = _v1.b.a;
								return $author$project$Elm$Page$CyclingDistance$CalculationSuccess(
									A2(
										$author$project$Elm$Service$Calculator$distanceBySpeed,
										t,
										$author$project$Elm$Service$Calculator$kmPerHToMiPerH(s)));
							default:
								break _v1$4;
						}
					}
				} else {
					break _v1$4;
				}
			}
			return $author$project$Elm$Page$CyclingDistance$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$CyclingDistance$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Element$Result$clearValue = function (model) {
	return _Utils_update(
		model,
		{E: $author$project$Elm$Element$Result$initialValue});
};
var $author$project$Elm$Page$CyclingDistance$db = 'cycling-distance';
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Elm$Service$Unit$Mile = 2;
var $author$project$Elm$Service$Unit$MilesPerHour = 1;
var $author$project$Elm$Service$Unit$Per100Yards = 3;
var $author$project$Elm$Service$Unit$PerMile = 1;
var $author$project$Elm$Service$Unit$Yard = 3;
var $author$project$Elm$Service$Unit$fromId = function (id) {
	switch (id) {
		case 0:
			return $author$project$Elm$Service$Unit$Distance(0);
		case 1:
			return $author$project$Elm$Service$Unit$Distance(1);
		case 2:
			return $author$project$Elm$Service$Unit$Distance(2);
		case 3:
			return $author$project$Elm$Service$Unit$Distance(3);
		case 4:
			return $author$project$Elm$Service$Unit$Time;
		case 5:
			return $author$project$Elm$Service$Unit$Pace(0);
		case 6:
			return $author$project$Elm$Service$Unit$Pace(1);
		case 7:
			return $author$project$Elm$Service$Unit$Pace(2);
		case 8:
			return $author$project$Elm$Service$Unit$Pace(3);
		case 9:
			return $author$project$Elm$Service$Unit$Speed(0);
		case 10:
			return $author$project$Elm$Service$Unit$Speed(1);
		default:
			return $author$project$Elm$Service$Unit$Distance(0);
	}
};
var $author$project$Elm$Port$getFromLocalStorage = _Platform_outgoingPort('getFromLocalStorage', $elm$json$Json$Encode$string);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$pow = _Basics_pow;
var $elm_community$basics_extra$Basics$Extra$maxSafeInteger = A2($elm$core$Basics$pow, 2, 53) - 1;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm_community$basics_extra$Basics$Extra$minSafeInteger = -$elm_community$basics_extra$Basics$Extra$maxSafeInteger;
var $elm_community$basics_extra$Basics$Extra$isSafeInteger = function (number) {
	return (_Utils_cmp($elm_community$basics_extra$Basics$Extra$minSafeInteger, number) < 1) && (_Utils_cmp($elm_community$basics_extra$Basics$Extra$maxSafeInteger, number) > -1);
};
var $elm$core$String$cons = _String_cons;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm_community$string_extra$String$Extra$changeCase = F2(
	function (mutator, word) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var head = _v0.a;
					var tail = _v0.b;
					return A2(
						$elm$core$String$cons,
						mutator(head),
						tail);
				},
				$elm$core$String$uncons(word)));
	});
var $elm$core$Char$toUpper = _Char_toUpper;
var $elm_community$string_extra$String$Extra$toSentenceCase = function (word) {
	return A2($elm_community$string_extra$String$Extra$changeCase, $elm$core$Char$toUpper, word);
};
var $author$project$Elm$Service$Validator$outOfRange = function (name) {
	return $elm_community$string_extra$String$Extra$toSentenceCase(name + ' calculation is out of range, please insert smaller values');
};
var $author$project$Elm$Port$saveToLocalStorage = _Platform_outgoingPort(
	'saveToLocalStorage',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$core$Basics$identity(b)
				]));
	});
var $author$project$Elm$Element$Input$setValue = F2(
	function (value, model) {
		return _Utils_update(
			model,
			{E: value});
	});
var $author$project$Elm$Element$Result$setValue = F2(
	function (value, model) {
		return _Utils_update(
			model,
			{E: value});
	});
var $author$project$Elm$Page$CyclingDistance$Storage = F5(
	function (time, speed, speedUnit, distanceUnit, isCalculated) {
		return {V: distanceUnit, aZ: isCalculated, j: speed, ab: speedUnit, n: time};
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$map5 = _Json_map5;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Elm$Page$CyclingDistance$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$CyclingDistance$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['time']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['speed']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['speedUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Elm$Page$CyclingDistance$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$string(storage.n)),
				_Utils_Tuple2(
				'speed',
				$elm$json$Json$Encode$string(storage.j)),
				_Utils_Tuple2(
				'speedUnit',
				$elm$json$Json$Encode$int(storage.ab)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Service$Unit$toId = function (unit) {
	switch (unit.$) {
		case 0:
			var type_ = unit.a;
			switch (type_) {
				case 0:
					return 0;
				case 1:
					return 1;
				case 2:
					return 2;
				default:
					return 3;
			}
		case 1:
			return 4;
		case 2:
			var type_ = unit.a;
			switch (type_) {
				case 0:
					return 5;
				case 1:
					return 6;
				case 2:
					return 7;
				default:
					return 8;
			}
		default:
			var type_ = unit.a;
			if (!type_) {
				return 9;
			} else {
				return 10;
			}
	}
};
var $author$project$Elm$Element$Input$ChangeValue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Element$Input$ConvertationFailed = {$: 2};
var $author$project$Elm$Element$Input$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Element$Input$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Element$Input$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Element$Input$UnitChanged = {$: 3};
var $author$project$Elm$Element$Input$ValueChanged = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Elm$Service$Unit$ConvertationSkipped = {$: 2};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$andMap = $elm$core$Maybe$map2($elm$core$Basics$apR);
var $author$project$Elm$Service$Unit$ConvertationFailed = {$: 1};
var $author$project$Elm$Service$Unit$Converted = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Service$Unit$convertationResult = function (result) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Elm$Service$Unit$ConvertationFailed,
		A2($elm$core$Maybe$map, $author$project$Elm$Service$Unit$Converted, result));
};
var $author$project$Elm$Service$Unit$isSafeFloat = function (num) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Maybe$Nothing,
		A2(
			$elm$core$Maybe$map,
			function (_float) {
				return $elm_community$basics_extra$Basics$Extra$isSafeInteger(
					$elm$core$Basics$floor(_float)) ? num : $elm$core$Maybe$Nothing;
			},
			num));
};
var $author$project$Elm$Service$Unit$isSafeInteger = function (num) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Maybe$Nothing,
		A2(
			$elm$core$Maybe$map,
			function (integer) {
				return $elm_community$basics_extra$Basics$Extra$isSafeInteger(integer) ? num : $elm$core$Maybe$Nothing;
			},
			num));
};
var $author$project$Elm$Service$Calculator$kmToM = function (km) {
	return $elm$core$Basics$round(km * 1000);
};
var $author$project$Elm$Service$Calculator$kmToMi = function (km) {
	return A2($author$project$Elm$Service$Calculator$roundTo, 1000, km / 1.60934);
};
var $author$project$Elm$Service$Calculator$kmToYd = function (km) {
	return $elm$core$Basics$round(km * 1093.613);
};
var $author$project$Elm$Service$Calculator$mToKm = function (m) {
	return m / 1000;
};
var $author$project$Elm$Service$Calculator$mToMi = function (m) {
	return A2($author$project$Elm$Service$Calculator$roundTo, 1000, m / 1609.34);
};
var $author$project$Elm$Service$Calculator$mToYd = function (m) {
	return $elm$core$Basics$round(m * 1.093613);
};
var $author$project$Elm$Service$Calculator$miToKm = function (mi) {
	return A2($author$project$Elm$Service$Calculator$roundTo, 1000, mi * 1.60934);
};
var $author$project$Elm$Service$Calculator$miToM = function (mi) {
	return $elm$core$Basics$round(mi * 1609.34);
};
var $author$project$Elm$Service$Calculator$miToYd = function (mi) {
	return $elm$core$Basics$round(mi * 1760);
};
var $author$project$Elm$Service$Calculator$ydToKm = function (yd) {
	return A2($author$project$Elm$Service$Calculator$roundTo, 1000, yd / 1093.613);
};
var $author$project$Elm$Service$Calculator$ydToM = function (yd) {
	return $elm$core$Basics$round(yd / 1.093613);
};
var $author$project$Elm$Service$Calculator$ydToMi = function (yd) {
	return A2($author$project$Elm$Service$Calculator$roundTo, 1000, yd / 1760);
};
var $author$project$Elm$Service$Unit$convertDistance = F3(
	function (from, to, value) {
		switch (from) {
			case 0:
				switch (to) {
					case 0:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 1:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromInt,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toFloat(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$kmToM)))));
					case 2:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromFloat,
								$author$project$Elm$Service$Unit$isSafeFloat(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toFloat(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$kmToMi)))));
					default:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromInt,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toFloat(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$kmToYd)))));
				}
			case 1:
				switch (to) {
					case 1:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 0:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromFloat,
								$author$project$Elm$Service$Unit$isSafeFloat(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toInt(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$mToKm)))));
					case 2:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromFloat,
								$author$project$Elm$Service$Unit$isSafeFloat(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toInt(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$mToMi)))));
					default:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromInt,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toInt(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$mToYd)))));
				}
			case 2:
				switch (to) {
					case 2:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 0:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromFloat,
								$author$project$Elm$Service$Unit$isSafeFloat(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toFloat(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$miToKm)))));
					case 1:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromInt,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toFloat(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$miToM)))));
					default:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromInt,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toFloat(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$miToYd)))));
				}
			default:
				switch (to) {
					case 3:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 0:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromFloat,
								$author$project$Elm$Service$Unit$isSafeFloat(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toInt(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$ydToKm)))));
					case 1:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromInt,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toInt(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$ydToM)))));
					default:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$fromFloat,
								$author$project$Elm$Service$Unit$isSafeFloat(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$String$toInt(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$ydToMi)))));
				}
		}
	});
var $author$project$Elm$Service$Validator$paceRegex = '^(\\d+):[0-5][0-9]$';
var $author$project$Elm$Service$Validator$isPace = $author$project$Elm$Service$Validator$isValueValid($author$project$Elm$Service$Validator$paceRegex);
var $author$project$Elm$Service$Calculator$minToSec = $elm$core$Basics$mul(60);
var $author$project$Elm$Service$Converter$paceToSec = function (value) {
	if ($author$project$Elm$Service$Validator$isPace(value)) {
		var _v0 = A2(
			$elm$core$List$map,
			$elm$core$String$toInt,
			A2($elm$core$String$split, ':', value));
		if ((((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) && (!_v0.b.b.b)) {
			var min = _v0.a.a;
			var _v1 = _v0.b;
			var sec = _v1.a.a;
			return $elm$core$Maybe$Just(
				$author$project$Elm$Service$Calculator$minToSec(min) + sec);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Elm$Service$Calculator$secPerKmToSecPerMi = function (sec) {
	return $elm$core$Basics$round(sec * 1.60934);
};
var $author$project$Elm$Service$Calculator$secPerMToSecPerYd = function (sec) {
	return $elm$core$Basics$round(sec / 1.093613);
};
var $author$project$Elm$Service$Calculator$secPerMiToSecPerKm = function (sec) {
	return $elm$core$Basics$round(sec / 1.60934);
};
var $author$project$Elm$Service$Calculator$secPerYdToSecPerM = function (sec) {
	return $elm$core$Basics$round(sec * 1.093613);
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $author$project$Elm$Service$Calculator$secToMin = function (sec) {
	return sec / 60;
};
var $author$project$Elm$Service$Converter$secToPace = function (sec) {
	var toString = function (_int) {
		return (_int < 10) ? ('0' + $elm$core$String$fromInt(_int)) : $elm$core$String$fromInt(_int);
	};
	var min = $elm$core$Basics$floor(
		$author$project$Elm$Service$Calculator$secToMin(sec));
	return $elm$core$String$concat(
		_List_fromArray(
			[
				toString(min),
				':',
				toString(
				sec - $author$project$Elm$Service$Calculator$minToSec(min))
			]));
};
var $author$project$Elm$Service$Unit$convertPace = F3(
	function (from, to, value) {
		switch (from) {
			case 0:
				switch (to) {
					case 0:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 1:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$author$project$Elm$Service$Converter$secToPace,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$author$project$Elm$Service$Converter$paceToSec(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$secPerKmToSecPerMi)))));
					case 2:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					default:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
				}
			case 1:
				switch (to) {
					case 1:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 0:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$author$project$Elm$Service$Converter$secToPace,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$author$project$Elm$Service$Converter$paceToSec(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$secPerMiToSecPerKm)))));
					case 2:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					default:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
				}
			case 2:
				switch (to) {
					case 2:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 3:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$author$project$Elm$Service$Converter$secToPace,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$author$project$Elm$Service$Converter$paceToSec(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$secPerMToSecPerYd)))));
					case 0:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					default:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
				}
			default:
				switch (to) {
					case 3:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					case 2:
						return $author$project$Elm$Service$Unit$convertationResult(
							A2(
								$elm$core$Maybe$map,
								$author$project$Elm$Service$Converter$secToPace,
								$author$project$Elm$Service$Unit$isSafeInteger(
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$author$project$Elm$Service$Converter$paceToSec(value),
										$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$secPerYdToSecPerM)))));
					case 0:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
					default:
						return $author$project$Elm$Service$Unit$ConvertationSkipped;
				}
		}
	});
var $author$project$Elm$Service$Unit$convertSpeed = F3(
	function (from, to, value) {
		if (!from) {
			if (!to) {
				return $author$project$Elm$Service$Unit$ConvertationSkipped;
			} else {
				return $author$project$Elm$Service$Unit$convertationResult(
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromFloat,
						$author$project$Elm$Service$Unit$isSafeFloat(
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								$elm$core$String$toFloat(value),
								$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$kmPerHToMiPerH)))));
			}
		} else {
			if (to === 1) {
				return $author$project$Elm$Service$Unit$ConvertationSkipped;
			} else {
				return $author$project$Elm$Service$Unit$convertationResult(
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromFloat,
						$author$project$Elm$Service$Unit$isSafeFloat(
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								$elm$core$String$toFloat(value),
								$elm$core$Maybe$Just($author$project$Elm$Service$Calculator$miPerHToKmPerH)))));
			}
		}
	});
var $author$project$Elm$Service$Unit$convert = F3(
	function (from, to, value) {
		var _v0 = _Utils_Tuple2(from, to);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var unitFrom = _v0.a.a;
						var unitTo = _v0.b.a;
						return A3($author$project$Elm$Service$Unit$convertDistance, unitFrom, unitTo, value);
					} else {
						break _v0$3;
					}
				case 2:
					if (_v0.b.$ === 2) {
						var unitFrom = _v0.a.a;
						var unitTo = _v0.b.a;
						return A3($author$project$Elm$Service$Unit$convertPace, unitFrom, unitTo, value);
					} else {
						break _v0$3;
					}
				case 3:
					if (_v0.b.$ === 3) {
						var unitFrom = _v0.a.a;
						var unitTo = _v0.b.a;
						return A3($author$project$Elm$Service$Unit$convertSpeed, unitFrom, unitTo, value);
					} else {
						break _v0$3;
					}
				default:
					break _v0$3;
			}
		}
		return $author$project$Elm$Service$Unit$ConvertationSkipped;
	});
var $elm$core$String$trim = _String_trim;
var $author$project$Elm$Element$Input$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var value = msg.a;
				var trimmed = $elm$core$String$trim(value);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{E: trimmed}),
					$author$project$Elm$Util$Cmd$fire(
						$author$project$Elm$Element$Input$Parent(
							A2($author$project$Elm$Element$Input$ValueChanged, model.E, trimmed))));
			case 1:
				var unit = msg.a;
				var convert = A3($author$project$Elm$Service$Unit$convert, model.t, unit, model.E);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{t: unit}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Elm$Util$Cmd$fire(
								$author$project$Elm$Element$Input$Parent($author$project$Elm$Element$Input$UnitChanged)),
								function () {
								switch (convert.$) {
									case 0:
										var v = convert.a;
										return $author$project$Elm$Util$Cmd$fire(
											A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Self, $author$project$Elm$Element$Input$ChangeValue)(v));
									case 2:
										return $elm$core$Platform$Cmd$none;
									default:
										return $elm$core$String$isEmpty(model.E) ? $elm$core$Platform$Cmd$none : $elm$core$Platform$Cmd$batch(
											_List_fromArray(
												[
													$author$project$Elm$Util$Cmd$fire(
													A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Parent, $author$project$Elm$Element$Input$ShowSnackbar)('Cannot convert this value')),
													$author$project$Elm$Util$Cmd$fire(
													$author$project$Elm$Element$Input$Parent($author$project$Elm$Element$Input$ConvertationFailed))
												]));
								}
							}()
							])));
			case 2:
				var unit = msg.a;
				var value = msg.b;
				var convert = A3($author$project$Elm$Service$Unit$convert, unit, model.t, value);
				return _Utils_Tuple2(
					model,
					function () {
						switch (convert.$) {
							case 0:
								var v = convert.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Self, $author$project$Elm$Element$Input$ChangeValue)(v));
							case 2:
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Self, $author$project$Elm$Element$Input$ChangeValue)(value));
							default:
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Parent, $author$project$Elm$Element$Input$ShowSnackbar)('Something went wrong. Please contact us'));
						}
					}());
			default:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{an: bool}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Elm$Element$Result$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Element$Result$UnitChanged = 0;
var $author$project$Elm$Element$Result$update = F2(
	function (msg, model) {
		var unit = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{t: unit}),
			$author$project$Elm$Util$Cmd$fire(
				$author$project$Elm$Element$Result$Parent(0)));
	});
var $author$project$Elm$Page$CyclingDistance$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingDistance$ValidationSuccess = {$: 1};
var $author$project$Elm$Service$Validator$fieldEmpty = function (name) {
	return $elm_community$string_extra$String$Extra$toSentenceCase(name + ' field is empty. Please insert a value');
};
var $author$project$Elm$Service$Validator$fieldNotValid = function (name) {
	return $elm_community$string_extra$String$Extra$toSentenceCase(name + ' is not valid, make sure you follow the pattern you can find under the field');
};
var $author$project$Elm$Service$Validator$fieldsEmpty = function (name) {
	return 'The fields are empty. Please fill the values to calculate ' + name;
};
var $author$project$Elm$Service$Validator$fieldsNotValid = 'Inserted values are not valid, please take a note at the hints under the fields';
var $elm$core$Basics$not = _Basics_not;
var $author$project$Elm$Page$CyclingDistance$validate = F2(
	function (time, speed) {
		var _v0 = _Utils_Tuple2(time.an, speed.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$CyclingDistance$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$CyclingDistance$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('time'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$CyclingDistance$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('speed'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(time.E),
					!$elm$core$String$isEmpty(speed.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$CyclingDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('distance'));
					} else {
						return $author$project$Elm$Page$CyclingDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('time'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$CyclingDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('speed'));
					} else {
						return $author$project$Elm$Page$CyclingDistance$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$CyclingDistance$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.n);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{n: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Self, $author$project$Elm$Page$CyclingDistance$TimeInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Parent, $author$project$Elm$Page$CyclingDistance$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingDistance$Self($author$project$Elm$Page$CyclingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.j);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Self, $author$project$Elm$Page$CyclingDistance$SpeedInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Parent, $author$project$Elm$Page$CyclingDistance$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingDistance$Self($author$project$Elm$Page$CyclingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Self, $author$project$Elm$Page$CyclingDistance$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$CyclingDistance$Self($author$project$Elm$Page$CyclingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$CyclingDistance$validate, model.n, model.j);
				var success = function (_float) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$elm$core$String$fromFloat(_float),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingDistance$Parent($author$project$Elm$Page$CyclingDistance$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$CyclingDistance$db,
										$author$project$Elm$Page$CyclingDistance$storageEncoder(
											{
												V: $author$project$Elm$Service$Unit$toId(model.bj.t),
												aZ: true,
												j: model.j.E,
												ab: $author$project$Elm$Service$Unit$toId(model.j.t),
												n: model.n.E
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Parent, $author$project$Elm$Page$CyclingDistance$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A3($author$project$Elm$Page$CyclingDistance$calculate, model.n, model.j, model.bj);
					if (calculation.$ === 1) {
						var distance = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(
							$elm$core$Basics$floor(distance)) ? success(distance) : error(
							$author$project$Elm$Service$Validator$outOfRange('distance'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aZ: false,
							bj: $author$project$Elm$Element$Result$clearValue(model.bj),
							j: A2($author$project$Elm$Element$Input$setValue, '', model.j),
							n: A2($author$project$Elm$Element$Input$setValue, '', model.n)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$CyclingDistance$db,
							$author$project$Elm$Page$CyclingDistance$storageEncoder(
								{
									V: $author$project$Elm$Service$Unit$toId(model.bj.t),
									aZ: false,
									j: '',
									ab: $author$project$Elm$Service$Unit$toId(model.j.t),
									n: ''
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$CyclingDistance$storageDecoder, value);
				var _v9 = model;
				var time = _v9.n;
				var speed = _v9.j;
				var result = _v9.bj;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedTimeInput = _Utils_update(
						time,
						{E: storage.n});
					var updatedSpeedInput = _Utils_update(
						speed,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.ab),
							E: storage.j
						});
					var updatedResultInput = _Utils_update(
						result,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedResultInput, j: updatedSpeedInput, n: updatedTimeInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$CyclingDistance$Self($author$project$Elm$Page$CyclingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$CyclingDistance$db));
		}
	});
var $author$project$Elm$Page$CyclingSpeed$CalculateSpeed = {$: 3};
var $author$project$Elm$Page$CyclingSpeed$DistanceInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$HideSnackbar = {$: 1};
var $author$project$Elm$Page$CyclingSpeed$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$TimeInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Service$Calculator$speed = F2(
	function (d, t) {
		return (t <= 0) ? 0 : A2($author$project$Elm$Service$Calculator$roundTo, 10, d / t);
	});
var $author$project$Elm$Page$CyclingSpeed$calculate = F3(
	function (distance, time, result) {
		var _v0 = _Utils_Tuple2(
			$elm$core$String$toFloat(distance.E),
			$author$project$Elm$Service$Converter$timeToHour(time.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var d = _v0.a.a;
			var t = _v0.b.a;
			var _v1 = _Utils_Tuple2(distance.t, result.t);
			_v1$4:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 3)) {
					if (_v1.b.a === 1) {
						switch (_v1.a.a) {
							case 2:
								var _v4 = _v1.a.a;
								var _v5 = _v1.b.a;
								return $author$project$Elm$Page$CyclingSpeed$CalculationSuccess(
									A2($author$project$Elm$Service$Calculator$speed, d, t));
							case 0:
								var _v6 = _v1.a.a;
								var _v7 = _v1.b.a;
								return $author$project$Elm$Page$CyclingSpeed$CalculationSuccess(
									A2(
										$author$project$Elm$Service$Calculator$speed,
										$author$project$Elm$Service$Calculator$kmToMi(d),
										t));
							default:
								break _v1$4;
						}
					} else {
						switch (_v1.a.a) {
							case 0:
								var _v2 = _v1.a.a;
								var _v3 = _v1.b.a;
								return $author$project$Elm$Page$CyclingSpeed$CalculationSuccess(
									A2($author$project$Elm$Service$Calculator$speed, d, t));
							case 2:
								var _v8 = _v1.a.a;
								var _v9 = _v1.b.a;
								return $author$project$Elm$Page$CyclingSpeed$CalculationSuccess(
									A2(
										$author$project$Elm$Service$Calculator$speed,
										$author$project$Elm$Service$Calculator$miToKm(d),
										t));
							default:
								break _v1$4;
						}
					}
				} else {
					break _v1$4;
				}
			}
			return $author$project$Elm$Page$CyclingSpeed$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$CyclingSpeed$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$CyclingSpeed$db = 'cycling-speed';
var $author$project$Elm$Page$CyclingSpeed$Storage = F5(
	function (distance, distanceUnit, time, resultUnit, isCalculated) {
		return {g: distance, V: distanceUnit, aZ: isCalculated, aa: resultUnit, n: time};
	});
var $author$project$Elm$Page$CyclingSpeed$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$CyclingSpeed$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distance']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['time']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['resultUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$CyclingSpeed$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'distance',
				$elm$json$Json$Encode$string(storage.g)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$string(storage.n)),
				_Utils_Tuple2(
				'resultUnit',
				$elm$json$Json$Encode$int(storage.aa)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$CyclingSpeed$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingSpeed$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$CyclingSpeed$validate = F2(
	function (distance, time) {
		var _v0 = _Utils_Tuple2(distance.an, time.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$CyclingSpeed$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$CyclingSpeed$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('distance'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$CyclingSpeed$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('time'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(distance.E),
					!$elm$core$String$isEmpty(time.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$CyclingSpeed$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('speed'));
					} else {
						return $author$project$Elm$Page$CyclingSpeed$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('distance'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$CyclingSpeed$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('time'));
					} else {
						return $author$project$Elm$Page$CyclingSpeed$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$CyclingSpeed$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.g);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Self, $author$project$Elm$Page$CyclingSpeed$DistanceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Parent, $author$project$Elm$Page$CyclingSpeed$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingSpeed$Self($author$project$Elm$Page$CyclingSpeed$CalculateSpeed)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.n);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{n: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Self, $author$project$Elm$Page$CyclingSpeed$TimeInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Parent, $author$project$Elm$Page$CyclingSpeed$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingSpeed$Self($author$project$Elm$Page$CyclingSpeed$CalculateSpeed)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Self, $author$project$Elm$Page$CyclingSpeed$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$CyclingSpeed$Self($author$project$Elm$Page$CyclingSpeed$CalculateSpeed)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$CyclingSpeed$validate, model.g, model.n);
				var success = function (_float) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$elm$core$String$fromFloat(_float),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingSpeed$Parent($author$project$Elm$Page$CyclingSpeed$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$CyclingSpeed$db,
										$author$project$Elm$Page$CyclingSpeed$storageEncoder(
											{
												g: model.g.E,
												V: $author$project$Elm$Service$Unit$toId(model.g.t),
												aZ: true,
												aa: $author$project$Elm$Service$Unit$toId(model.bj.t),
												n: model.n.E
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Parent, $author$project$Elm$Page$CyclingSpeed$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A3($author$project$Elm$Page$CyclingSpeed$calculate, model.g, model.n, model.bj);
					if (calculation.$ === 1) {
						var speed = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(
							$elm$core$Basics$floor(speed)) ? success(speed) : error(
							$author$project$Elm$Service$Validator$outOfRange('speed'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A2($author$project$Elm$Element$Input$setValue, '', model.g),
							aZ: false,
							bj: $author$project$Elm$Element$Result$clearValue(model.bj),
							n: A2($author$project$Elm$Element$Input$setValue, '', model.n)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$CyclingSpeed$db,
							$author$project$Elm$Page$CyclingSpeed$storageEncoder(
								{
									g: '',
									V: $author$project$Elm$Service$Unit$toId(model.g.t),
									aZ: false,
									aa: $author$project$Elm$Service$Unit$toId(model.bj.t),
									n: ''
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$CyclingSpeed$storageDecoder, value);
				var _v9 = model;
				var distance = _v9.g;
				var time = _v9.n;
				var result = _v9.bj;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedTimeInput = _Utils_update(
						time,
						{E: storage.n});
					var updatedResult = _Utils_update(
						result,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.aa)
						});
					var updatedDistanceInput = _Utils_update(
						distance,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V),
							E: storage.g
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedDistanceInput, bj: updatedResult, n: updatedTimeInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$CyclingSpeed$Self($author$project$Elm$Page$CyclingSpeed$CalculateSpeed)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$CyclingSpeed$db));
		}
	});
var $author$project$Elm$Page$CyclingTime$CalculateTime = {$: 3};
var $author$project$Elm$Page$CyclingTime$DistanceInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingTime$HideSnackbar = {$: 1};
var $author$project$Elm$Page$CyclingTime$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$CyclingTime$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$CyclingTime$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingTime$SpeedInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$CyclingTime$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingTime$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Service$Calculator$timeBySpeed = F2(
	function (d, s) {
		return d / s;
	});
var $author$project$Elm$Page$CyclingTime$calculate = F2(
	function (distance, speed) {
		var _v0 = _Utils_Tuple2(
			$elm$core$String$toFloat(distance.E),
			$elm$core$String$toFloat(speed.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var d = _v0.a.a;
			var s = _v0.b.a;
			var _v1 = _Utils_Tuple2(distance.t, speed.t);
			_v1$4:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 3)) {
					if (_v1.b.a === 1) {
						switch (_v1.a.a) {
							case 2:
								var _v4 = _v1.a.a;
								var _v5 = _v1.b.a;
								return $author$project$Elm$Page$CyclingTime$CalculationSuccess(
									$elm$core$Basics$round(
										3600 * A2($author$project$Elm$Service$Calculator$timeBySpeed, d, s)));
							case 0:
								var _v6 = _v1.a.a;
								var _v7 = _v1.b.a;
								return $author$project$Elm$Page$CyclingTime$CalculationSuccess(
									$elm$core$Basics$round(
										3600 * A2(
											$author$project$Elm$Service$Calculator$timeBySpeed,
											d,
											$author$project$Elm$Service$Calculator$miPerHToKmPerH(s))));
							default:
								break _v1$4;
						}
					} else {
						switch (_v1.a.a) {
							case 0:
								var _v2 = _v1.a.a;
								var _v3 = _v1.b.a;
								return $author$project$Elm$Page$CyclingTime$CalculationSuccess(
									$elm$core$Basics$round(
										3600 * A2($author$project$Elm$Service$Calculator$timeBySpeed, d, s)));
							case 2:
								var _v8 = _v1.a.a;
								var _v9 = _v1.b.a;
								return $author$project$Elm$Page$CyclingTime$CalculationSuccess(
									$elm$core$Basics$round(
										3600 * A2(
											$author$project$Elm$Service$Calculator$timeBySpeed,
											d,
											$author$project$Elm$Service$Calculator$kmPerHToMiPerH(s))));
							default:
								break _v1$4;
						}
					}
				} else {
					break _v1$4;
				}
			}
			return $author$project$Elm$Page$CyclingTime$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$CyclingTime$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$CyclingTime$db = 'cycling-time';
var $author$project$Elm$Service$Calculator$hToSec = $elm$core$Basics$mul(3600);
var $author$project$Elm$Service$Converter$secToTime = function (sec) {
	var toString = function (_int) {
		return (_int < 10) ? ('0' + $elm$core$String$fromInt(_int)) : $elm$core$String$fromInt(_int);
	};
	var hour = $elm$core$Basics$floor(
		$author$project$Elm$Service$Calculator$secToH(sec));
	var min = $elm$core$Basics$floor(
		$author$project$Elm$Service$Calculator$secToMin(
			sec - $author$project$Elm$Service$Calculator$hToSec(hour)));
	return $elm$core$String$concat(
		_List_fromArray(
			[
				toString(hour),
				':',
				toString(min),
				':',
				toString(
				sec - ($author$project$Elm$Service$Calculator$hToSec(hour) + $author$project$Elm$Service$Calculator$minToSec(min)))
			]));
};
var $author$project$Elm$Page$CyclingTime$Storage = F5(
	function (distance, distanceUnit, speed, speedUnit, isCalculated) {
		return {g: distance, V: distanceUnit, aZ: isCalculated, j: speed, ab: speedUnit};
	});
var $author$project$Elm$Page$CyclingTime$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$CyclingTime$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distance']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['speed']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['speedUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$CyclingTime$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'distance',
				$elm$json$Json$Encode$string(storage.g)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'speed',
				$elm$json$Json$Encode$string(storage.j)),
				_Utils_Tuple2(
				'speedUnit',
				$elm$json$Json$Encode$int(storage.ab)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$CyclingTime$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$CyclingTime$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$CyclingTime$validate = F2(
	function (distance, speed) {
		var _v0 = _Utils_Tuple2(distance.an, speed.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$CyclingTime$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$CyclingTime$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('distance'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$CyclingTime$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('speed'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(distance.E),
					!$elm$core$String$isEmpty(speed.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$CyclingTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('time'));
					} else {
						return $author$project$Elm$Page$CyclingTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('distance'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$CyclingTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('speed'));
					} else {
						return $author$project$Elm$Page$CyclingTime$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$CyclingTime$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.g);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Self, $author$project$Elm$Page$CyclingTime$DistanceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Parent, $author$project$Elm$Page$CyclingTime$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingTime$Self($author$project$Elm$Page$CyclingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.j);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Self, $author$project$Elm$Page$CyclingTime$SpeedInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Parent, $author$project$Elm$Page$CyclingTime$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingTime$Self($author$project$Elm$Page$CyclingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Self, $author$project$Elm$Page$CyclingTime$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$CyclingTime$Self($author$project$Elm$Page$CyclingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$CyclingTime$validate, model.g, model.j);
				var success = function (_int) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$author$project$Elm$Service$Converter$secToTime(_int),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$CyclingTime$Parent($author$project$Elm$Page$CyclingTime$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$CyclingTime$db,
										$author$project$Elm$Page$CyclingTime$storageEncoder(
											{
												g: model.g.E,
												V: $author$project$Elm$Service$Unit$toId(model.g.t),
												aZ: true,
												j: model.j.E,
												ab: $author$project$Elm$Service$Unit$toId(model.j.t)
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Parent, $author$project$Elm$Page$CyclingTime$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A2($author$project$Elm$Page$CyclingTime$calculate, model.g, model.j);
					if (calculation.$ === 1) {
						var sec = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(sec) ? success(sec) : error(
							$author$project$Elm$Service$Validator$outOfRange('time'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A2($author$project$Elm$Element$Input$setValue, '', model.g),
							aZ: false,
							bj: $author$project$Elm$Element$Result$clearValue(model.bj),
							j: A2($author$project$Elm$Element$Input$setValue, '', model.j)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$CyclingTime$db,
							$author$project$Elm$Page$CyclingTime$storageEncoder(
								{
									g: '',
									V: $author$project$Elm$Service$Unit$toId(model.g.t),
									aZ: false,
									j: '',
									ab: $author$project$Elm$Service$Unit$toId(model.j.t)
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$CyclingTime$storageDecoder, value);
				var _v9 = model;
				var distance = _v9.g;
				var speed = _v9.j;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedSpeedInput = _Utils_update(
						speed,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.ab),
							E: storage.j
						});
					var updatedDistanceInput = _Utils_update(
						distance,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V),
							E: storage.g
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedDistanceInput, j: updatedSpeedInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$CyclingTime$Self($author$project$Elm$Page$CyclingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$CyclingTime$db));
		}
	});
var $author$project$Elm$Page$Home$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Elm$Page$RunningDistance$CalculateDistance = {$: 3};
var $author$project$Elm$Page$RunningDistance$HideSnackbar = {$: 1};
var $author$project$Elm$Page$RunningDistance$PaceInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$RunningDistance$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$RunningDistance$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$RunningDistance$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningDistance$TimeInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningDistance$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningDistance$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Service$Calculator$distanceByPace = F2(
	function (timeSec, paceSec) {
		return (paceSec <= 0) ? 0 : A2($author$project$Elm$Service$Calculator$roundTo, 1000, timeSec / paceSec);
	});
var $author$project$Elm$Service$Converter$timeToSec = function (value) {
	if ($author$project$Elm$Service$Validator$isTime(value)) {
		var _v0 = A2(
			$elm$core$List$map,
			$elm$core$String$toInt,
			A2($elm$core$String$split, ':', value));
		if ((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && (!_v0.b.b.a.$)) && (!_v0.b.b.b.b)) {
			var h = _v0.a.a;
			var _v1 = _v0.b;
			var min = _v1.a.a;
			var _v2 = _v1.b;
			var sec = _v2.a.a;
			return $elm$core$Maybe$Just(
				($author$project$Elm$Service$Calculator$hToSec(h) + $author$project$Elm$Service$Calculator$minToSec(min)) + sec);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Elm$Page$RunningDistance$calculate = F3(
	function (time, pace, result) {
		var _v0 = _Utils_Tuple2(
			$author$project$Elm$Service$Converter$timeToSec(time.E),
			$author$project$Elm$Service$Converter$paceToSec(pace.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var t = _v0.a.a;
			var p = _v0.b.a;
			var _v1 = _Utils_Tuple2(result.t, pace.t);
			_v1$8:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 2)) {
					switch (_v1.b.a) {
						case 1:
							switch (_v1.a.a) {
								case 0:
									var _v6 = _v1.a.a;
									var _v7 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$miToKm(
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p)));
								case 1:
									var _v8 = _v1.a.a;
									var _v9 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$miToM(
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p)));
								case 2:
									var _v10 = _v1.a.a;
									var _v11 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$distanceByPace, t, p));
								default:
									var _v14 = _v1.a.a;
									var _v15 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$miToYd(
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p)));
							}
						case 0:
							switch (_v1.a.a) {
								case 0:
									var _v2 = _v1.a.a;
									var _v3 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$distanceByPace, t, p));
								case 1:
									var _v4 = _v1.a.a;
									var _v5 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$kmToM(
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p)));
								case 2:
									var _v12 = _v1.a.a;
									var _v13 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$kmToMi(
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p)));
								default:
									var _v16 = _v1.a.a;
									var _v17 = _v1.b.a;
									return $author$project$Elm$Page$RunningDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$kmToYd(
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p)));
							}
						default:
							break _v1$8;
					}
				} else {
					break _v1$8;
				}
			}
			return $author$project$Elm$Page$RunningDistance$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$RunningDistance$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$RunningDistance$db = 'running-distance';
var $author$project$Elm$Page$RunningDistance$Storage = F5(
	function (time, pace, paceUnit, distanceUnit, isCalculated) {
		return {V: distanceUnit, aZ: isCalculated, i: pace, _: paceUnit, n: time};
	});
var $author$project$Elm$Page$RunningDistance$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$RunningDistance$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['time']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['pace']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['paceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$RunningDistance$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$string(storage.n)),
				_Utils_Tuple2(
				'pace',
				$elm$json$Json$Encode$string(storage.i)),
				_Utils_Tuple2(
				'paceUnit',
				$elm$json$Json$Encode$int(storage._)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$RunningDistance$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningDistance$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$RunningDistance$validate = F2(
	function (time, pace) {
		var _v0 = _Utils_Tuple2(time.an, pace.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$RunningDistance$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$RunningDistance$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('time'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$RunningDistance$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('pace'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(time.E),
					!$elm$core$String$isEmpty(pace.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$RunningDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('distance'));
					} else {
						return $author$project$Elm$Page$RunningDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('time'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$RunningDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('pace'));
					} else {
						return $author$project$Elm$Page$RunningDistance$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$RunningDistance$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.n);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{n: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Self, $author$project$Elm$Page$RunningDistance$TimeInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Parent, $author$project$Elm$Page$RunningDistance$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningDistance$Self($author$project$Elm$Page$RunningDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.i);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{i: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Self, $author$project$Elm$Page$RunningDistance$PaceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Parent, $author$project$Elm$Page$RunningDistance$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningDistance$Self($author$project$Elm$Page$RunningDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Self, $author$project$Elm$Page$RunningDistance$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$RunningDistance$Self($author$project$Elm$Page$RunningDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$RunningDistance$validate, model.n, model.i);
				var success = function (_float) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$elm$core$String$fromFloat(_float),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningDistance$Parent($author$project$Elm$Page$RunningDistance$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$RunningDistance$db,
										$author$project$Elm$Page$RunningDistance$storageEncoder(
											{
												V: $author$project$Elm$Service$Unit$toId(model.bj.t),
												aZ: true,
												i: model.i.E,
												_: $author$project$Elm$Service$Unit$toId(model.i.t),
												n: model.n.E
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Parent, $author$project$Elm$Page$RunningDistance$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A3($author$project$Elm$Page$RunningDistance$calculate, model.n, model.i, model.bj);
					if (calculation.$ === 1) {
						var distance = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(
							$elm$core$Basics$floor(distance)) ? success(distance) : error(
							$author$project$Elm$Service$Validator$outOfRange('distance'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aZ: false,
							i: A2($author$project$Elm$Element$Input$setValue, '', model.i),
							bj: $author$project$Elm$Element$Result$clearValue(model.bj),
							n: A2($author$project$Elm$Element$Input$setValue, '', model.n)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$RunningDistance$db,
							$author$project$Elm$Page$RunningDistance$storageEncoder(
								{
									V: $author$project$Elm$Service$Unit$toId(model.bj.t),
									aZ: false,
									i: '',
									_: $author$project$Elm$Service$Unit$toId(model.i.t),
									n: ''
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$RunningDistance$storageDecoder, value);
				var _v9 = model;
				var time = _v9.n;
				var pace = _v9.i;
				var result = _v9.bj;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedTimeInput = _Utils_update(
						time,
						{E: storage.n});
					var updatedResultInput = _Utils_update(
						result,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V)
						});
					var updatedPaceInput = _Utils_update(
						pace,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage._),
							E: storage.i
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{i: updatedPaceInput, bj: updatedResultInput, n: updatedTimeInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$RunningDistance$Self($author$project$Elm$Page$RunningDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$RunningDistance$db));
		}
	});
var $author$project$Elm$Page$RunningPace$CalculatePace = {$: 3};
var $author$project$Elm$Page$RunningPace$DistanceInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningPace$HideSnackbar = {$: 1};
var $author$project$Elm$Page$RunningPace$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$RunningPace$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$RunningPace$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningPace$TimeInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$RunningPace$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningPace$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Service$Calculator$pace = F2(
	function (sec, d) {
		return (d <= 0) ? 0 : $elm$core$Basics$floor(sec / d);
	});
var $author$project$Elm$Page$RunningPace$calculate = F3(
	function (distance, time, result) {
		var _v0 = _Utils_Tuple2(
			$elm$core$String$toFloat(distance.E),
			$author$project$Elm$Service$Converter$timeToSec(time.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var d = _v0.a.a;
			var t = _v0.b.a;
			var _v1 = _Utils_Tuple2(distance.t, result.t);
			_v1$8:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 2)) {
					switch (_v1.b.a) {
						case 1:
							switch (_v1.a.a) {
								case 0:
									var _v6 = _v1.a.a;
									var _v7 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$kmToMi(d)));
								case 1:
									var _v8 = _v1.a.a;
									var _v9 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$mToMi(
												$elm$core$Basics$round(d))));
								case 2:
									var _v10 = _v1.a.a;
									var _v11 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$pace, t, d));
								default:
									var _v14 = _v1.a.a;
									var _v15 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$ydToMi(
												$elm$core$Basics$round(d))));
							}
						case 0:
							switch (_v1.a.a) {
								case 0:
									var _v2 = _v1.a.a;
									var _v3 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$pace, t, d));
								case 1:
									var _v4 = _v1.a.a;
									var _v5 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$mToKm(
												$elm$core$Basics$round(d))));
								case 2:
									var _v12 = _v1.a.a;
									var _v13 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$miToKm(d)));
								default:
									var _v16 = _v1.a.a;
									var _v17 = _v1.b.a;
									return $author$project$Elm$Page$RunningPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$ydToKm(
												$elm$core$Basics$round(d))));
							}
						default:
							break _v1$8;
					}
				} else {
					break _v1$8;
				}
			}
			return $author$project$Elm$Page$RunningPace$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$RunningPace$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$RunningPace$db = 'running-pace';
var $author$project$Elm$Page$RunningPace$Storage = F5(
	function (distance, distanceUnit, time, resultUnit, isCalculated) {
		return {g: distance, V: distanceUnit, aZ: isCalculated, aa: resultUnit, n: time};
	});
var $author$project$Elm$Page$RunningPace$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$RunningPace$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distance']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['time']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['resultUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$RunningPace$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'distance',
				$elm$json$Json$Encode$string(storage.g)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$string(storage.n)),
				_Utils_Tuple2(
				'resultUnit',
				$elm$json$Json$Encode$int(storage.aa)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$RunningPace$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningPace$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$RunningPace$validate = F2(
	function (distance, time) {
		var _v0 = _Utils_Tuple2(distance.an, time.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$RunningPace$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$RunningPace$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('distance'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$RunningPace$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('time'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(distance.E),
					!$elm$core$String$isEmpty(time.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$RunningPace$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('pace'));
					} else {
						return $author$project$Elm$Page$RunningPace$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('distance'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$RunningPace$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('time'));
					} else {
						return $author$project$Elm$Page$RunningPace$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$RunningPace$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.g);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Self, $author$project$Elm$Page$RunningPace$DistanceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Parent, $author$project$Elm$Page$RunningPace$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningPace$Self($author$project$Elm$Page$RunningPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.n);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{n: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Self, $author$project$Elm$Page$RunningPace$TimeInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Parent, $author$project$Elm$Page$RunningPace$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningPace$Self($author$project$Elm$Page$RunningPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Self, $author$project$Elm$Page$RunningPace$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$RunningPace$Self($author$project$Elm$Page$RunningPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$RunningPace$validate, model.g, model.n);
				var success = function (_int) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$author$project$Elm$Service$Converter$secToPace(_int),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningPace$Parent($author$project$Elm$Page$RunningPace$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$RunningPace$db,
										$author$project$Elm$Page$RunningPace$storageEncoder(
											{
												g: model.g.E,
												V: $author$project$Elm$Service$Unit$toId(model.g.t),
												aZ: true,
												aa: $author$project$Elm$Service$Unit$toId(model.bj.t),
												n: model.n.E
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Parent, $author$project$Elm$Page$RunningPace$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A3($author$project$Elm$Page$RunningPace$calculate, model.g, model.n, model.bj);
					if (calculation.$ === 1) {
						var sec = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(sec) ? success(sec) : error(
							$author$project$Elm$Service$Validator$outOfRange('pace'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A2($author$project$Elm$Element$Input$setValue, '', model.g),
							aZ: false,
							bj: $author$project$Elm$Element$Result$clearValue(model.bj),
							n: A2($author$project$Elm$Element$Input$setValue, '', model.n)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$RunningPace$db,
							$author$project$Elm$Page$RunningPace$storageEncoder(
								{
									g: '',
									V: $author$project$Elm$Service$Unit$toId(model.g.t),
									aZ: false,
									aa: $author$project$Elm$Service$Unit$toId(model.bj.t),
									n: ''
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$RunningPace$storageDecoder, value);
				var _v9 = model;
				var distance = _v9.g;
				var time = _v9.n;
				var result = _v9.bj;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedTimeInput = _Utils_update(
						time,
						{E: storage.n});
					var updatedResult = _Utils_update(
						result,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.aa)
						});
					var updatedDistanceInput = _Utils_update(
						distance,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V),
							E: storage.g
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedDistanceInput, bj: updatedResult, n: updatedTimeInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$RunningPace$Self($author$project$Elm$Page$RunningPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$RunningPace$db));
		}
	});
var $author$project$Elm$Page$RunningTime$CalculateTime = {$: 3};
var $author$project$Elm$Page$RunningTime$DistanceInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningTime$HideSnackbar = {$: 1};
var $author$project$Elm$Page$RunningTime$PaceInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$RunningTime$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$RunningTime$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$RunningTime$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningTime$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningTime$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Service$Calculator$timeByPace = F2(
	function (d, sec) {
		return $elm$core$Basics$floor(d * sec);
	});
var $author$project$Elm$Page$RunningTime$calculate = F2(
	function (distance, pace) {
		var _v0 = _Utils_Tuple2(
			$elm$core$String$toFloat(distance.E),
			$author$project$Elm$Service$Converter$paceToSec(pace.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var d = _v0.a.a;
			var p = _v0.b.a;
			var _v1 = _Utils_Tuple2(distance.t, pace.t);
			_v1$8:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 2)) {
					switch (_v1.b.a) {
						case 1:
							switch (_v1.a.a) {
								case 0:
									var _v6 = _v1.a.a;
									var _v7 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$kmToMi(d),
											p));
								case 1:
									var _v8 = _v1.a.a;
									var _v9 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$mToMi(
												$elm$core$Basics$round(d)),
											p));
								case 2:
									var _v10 = _v1.a.a;
									var _v11 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$timeByPace, d, p));
								default:
									var _v14 = _v1.a.a;
									var _v15 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$ydToMi(
												$elm$core$Basics$round(d)),
											p));
							}
						case 0:
							switch (_v1.a.a) {
								case 0:
									var _v2 = _v1.a.a;
									var _v3 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$timeByPace, d, p));
								case 1:
									var _v4 = _v1.a.a;
									var _v5 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$mToKm(
												$elm$core$Basics$round(d)),
											p));
								case 2:
									var _v12 = _v1.a.a;
									var _v13 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$miToKm(d),
											p));
								default:
									var _v16 = _v1.a.a;
									var _v17 = _v1.b.a;
									return $author$project$Elm$Page$RunningTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$ydToKm(
												$elm$core$Basics$round(d)),
											p));
							}
						default:
							break _v1$8;
					}
				} else {
					break _v1$8;
				}
			}
			return $author$project$Elm$Page$RunningTime$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$RunningTime$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$RunningTime$db = 'running-time';
var $author$project$Elm$Page$RunningTime$Storage = F5(
	function (distance, distanceUnit, pace, paceUnit, isCalculated) {
		return {g: distance, V: distanceUnit, aZ: isCalculated, i: pace, _: paceUnit};
	});
var $author$project$Elm$Page$RunningTime$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$RunningTime$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distance']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['pace']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['paceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$RunningTime$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'distance',
				$elm$json$Json$Encode$string(storage.g)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'pace',
				$elm$json$Json$Encode$string(storage.i)),
				_Utils_Tuple2(
				'paceUnit',
				$elm$json$Json$Encode$int(storage._)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$RunningTime$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$RunningTime$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$RunningTime$validate = F2(
	function (distance, pace) {
		var _v0 = _Utils_Tuple2(distance.an, pace.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$RunningTime$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$RunningTime$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('distance'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$RunningTime$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('pace'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(distance.E),
					!$elm$core$String$isEmpty(pace.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$RunningTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('time'));
					} else {
						return $author$project$Elm$Page$RunningTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('distance'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$RunningTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('pace'));
					} else {
						return $author$project$Elm$Page$RunningTime$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$RunningTime$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.g);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Self, $author$project$Elm$Page$RunningTime$DistanceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Parent, $author$project$Elm$Page$RunningTime$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningTime$Self($author$project$Elm$Page$RunningTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.i);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{i: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Self, $author$project$Elm$Page$RunningTime$PaceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Parent, $author$project$Elm$Page$RunningTime$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningTime$Self($author$project$Elm$Page$RunningTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Self, $author$project$Elm$Page$RunningTime$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$RunningTime$Self($author$project$Elm$Page$RunningTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$RunningTime$validate, model.g, model.i);
				var success = function (_int) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$author$project$Elm$Service$Converter$secToTime(_int),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$RunningTime$Parent($author$project$Elm$Page$RunningTime$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$RunningTime$db,
										$author$project$Elm$Page$RunningTime$storageEncoder(
											{
												g: model.g.E,
												V: $author$project$Elm$Service$Unit$toId(model.g.t),
												aZ: true,
												i: model.i.E,
												_: $author$project$Elm$Service$Unit$toId(model.i.t)
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Parent, $author$project$Elm$Page$RunningTime$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A2($author$project$Elm$Page$RunningTime$calculate, model.g, model.i);
					if (calculation.$ === 1) {
						var sec = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(sec) ? success(sec) : error(
							$author$project$Elm$Service$Validator$outOfRange('time'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A2($author$project$Elm$Element$Input$setValue, '', model.g),
							aZ: false,
							i: A2($author$project$Elm$Element$Input$setValue, '', model.i),
							bj: $author$project$Elm$Element$Result$clearValue(model.bj)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$RunningTime$db,
							$author$project$Elm$Page$RunningTime$storageEncoder(
								{
									g: '',
									V: $author$project$Elm$Service$Unit$toId(model.g.t),
									aZ: false,
									i: '',
									_: $author$project$Elm$Service$Unit$toId(model.i.t)
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$RunningTime$storageDecoder, value);
				var _v9 = model;
				var distance = _v9.g;
				var pace = _v9.i;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedPaceInput = _Utils_update(
						pace,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage._),
							E: storage.i
						});
					var updatedDistanceInput = _Utils_update(
						distance,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V),
							E: storage.g
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedDistanceInput, i: updatedPaceInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$RunningTime$Self($author$project$Elm$Page$RunningTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$RunningTime$db));
		}
	});
var $author$project$Elm$Page$SwimmingDistance$CalculateDistance = {$: 3};
var $author$project$Elm$Page$SwimmingDistance$HideSnackbar = {$: 1};
var $author$project$Elm$Page$SwimmingDistance$PaceInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$TimeInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$calculate = F3(
	function (time, pace, result) {
		var _v0 = _Utils_Tuple2(
			$author$project$Elm$Service$Converter$timeToSec(time.E),
			$author$project$Elm$Service$Converter$paceToSec(pace.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var t = _v0.a.a;
			var p = _v0.b.a;
			var _v1 = _Utils_Tuple2(result.t, pace.t);
			_v1$8:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 2)) {
					switch (_v1.b.a) {
						case 3:
							switch (_v1.a.a) {
								case 0:
									var _v6 = _v1.a.a;
									var _v7 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$ydToKm(
											$elm$core$Basics$round(
												A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100)));
								case 1:
									var _v8 = _v1.a.a;
									var _v9 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$ydToM(
											$elm$core$Basics$round(
												A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100)));
								case 2:
									var _v10 = _v1.a.a;
									var _v11 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$ydToMi(
											$elm$core$Basics$round(
												A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100)));
								default:
									var _v14 = _v1.a.a;
									var _v15 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$roundTo,
											1000,
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100));
							}
						case 2:
							switch (_v1.a.a) {
								case 0:
									var _v2 = _v1.a.a;
									var _v3 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$mToKm(
											$elm$core$Basics$round(
												A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100)));
								case 1:
									var _v4 = _v1.a.a;
									var _v5 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$roundTo,
											1000,
											A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100));
								case 2:
									var _v12 = _v1.a.a;
									var _v13 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$mToMi(
											$elm$core$Basics$round(
												A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100)));
								default:
									var _v16 = _v1.a.a;
									var _v17 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingDistance$CalculationSuccess(
										$author$project$Elm$Service$Calculator$mToYd(
											$elm$core$Basics$round(
												A2($author$project$Elm$Service$Calculator$distanceByPace, t, p) * 100)));
							}
						default:
							break _v1$8;
					}
				} else {
					break _v1$8;
				}
			}
			return $author$project$Elm$Page$SwimmingDistance$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$SwimmingDistance$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$SwimmingDistance$db = 'swimming-distance';
var $author$project$Elm$Page$SwimmingDistance$Storage = F5(
	function (time, pace, paceUnit, distanceUnit, isCalculated) {
		return {V: distanceUnit, aZ: isCalculated, i: pace, _: paceUnit, n: time};
	});
var $author$project$Elm$Page$SwimmingDistance$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$SwimmingDistance$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['time']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['pace']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['paceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$SwimmingDistance$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$string(storage.n)),
				_Utils_Tuple2(
				'pace',
				$elm$json$Json$Encode$string(storage.i)),
				_Utils_Tuple2(
				'paceUnit',
				$elm$json$Json$Encode$int(storage._)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$SwimmingDistance$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingDistance$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$SwimmingDistance$validate = F2(
	function (time, pace) {
		var _v0 = _Utils_Tuple2(time.an, pace.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$SwimmingDistance$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$SwimmingDistance$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('time'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$SwimmingDistance$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('pace'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(time.E),
					!$elm$core$String$isEmpty(pace.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$SwimmingDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('distance'));
					} else {
						return $author$project$Elm$Page$SwimmingDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('time'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$SwimmingDistance$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('pace'));
					} else {
						return $author$project$Elm$Page$SwimmingDistance$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$SwimmingDistance$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.n);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{n: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Self, $author$project$Elm$Page$SwimmingDistance$TimeInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Parent, $author$project$Elm$Page$SwimmingDistance$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingDistance$Self($author$project$Elm$Page$SwimmingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.i);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{i: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Self, $author$project$Elm$Page$SwimmingDistance$PaceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Parent, $author$project$Elm$Page$SwimmingDistance$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingDistance$Self($author$project$Elm$Page$SwimmingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Self, $author$project$Elm$Page$SwimmingDistance$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$SwimmingDistance$Self($author$project$Elm$Page$SwimmingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$SwimmingDistance$validate, model.n, model.i);
				var success = function (_float) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$elm$core$String$fromFloat(_float),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingDistance$Parent($author$project$Elm$Page$SwimmingDistance$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$SwimmingDistance$db,
										$author$project$Elm$Page$SwimmingDistance$storageEncoder(
											{
												V: $author$project$Elm$Service$Unit$toId(model.bj.t),
												aZ: true,
												i: model.i.E,
												_: $author$project$Elm$Service$Unit$toId(model.i.t),
												n: model.n.E
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Parent, $author$project$Elm$Page$SwimmingDistance$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A3($author$project$Elm$Page$SwimmingDistance$calculate, model.n, model.i, model.bj);
					if (calculation.$ === 1) {
						var distance = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(
							$elm$core$Basics$floor(distance)) ? success(distance) : error(
							$author$project$Elm$Service$Validator$outOfRange('distance'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aZ: false,
							i: A2($author$project$Elm$Element$Input$setValue, '', model.i),
							bj: $author$project$Elm$Element$Result$clearValue(model.bj),
							n: A2($author$project$Elm$Element$Input$setValue, '', model.n)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$SwimmingDistance$db,
							$author$project$Elm$Page$SwimmingDistance$storageEncoder(
								{
									V: $author$project$Elm$Service$Unit$toId(model.bj.t),
									aZ: false,
									i: '',
									_: $author$project$Elm$Service$Unit$toId(model.i.t),
									n: ''
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$SwimmingDistance$storageDecoder, value);
				var _v9 = model;
				var time = _v9.n;
				var pace = _v9.i;
				var result = _v9.bj;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedTimeInput = _Utils_update(
						time,
						{E: storage.n});
					var updatedResultInput = _Utils_update(
						result,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V)
						});
					var updatedPaceInput = _Utils_update(
						pace,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage._),
							E: storage.i
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{i: updatedPaceInput, bj: updatedResultInput, n: updatedTimeInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$SwimmingDistance$Self($author$project$Elm$Page$SwimmingDistance$CalculateDistance)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$SwimmingDistance$db));
		}
	});
var $author$project$Elm$Page$SwimmingPace$CalculatePace = {$: 3};
var $author$project$Elm$Page$SwimmingPace$DistanceInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingPace$HideSnackbar = {$: 1};
var $author$project$Elm$Page$SwimmingPace$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingPace$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$SwimmingPace$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingPace$TimeInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingPace$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingPace$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingPace$calculate = F3(
	function (distance, time, result) {
		var _v0 = _Utils_Tuple2(
			$elm$core$String$toFloat(distance.E),
			$author$project$Elm$Service$Converter$timeToSec(time.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var d = _v0.a.a;
			var t = _v0.b.a;
			var _v1 = _Utils_Tuple2(distance.t, result.t);
			_v1$8:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 2)) {
					switch (_v1.b.a) {
						case 3:
							switch (_v1.a.a) {
								case 0:
									var _v6 = _v1.a.a;
									var _v7 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$kmToYd(d) / 100));
								case 1:
									var _v8 = _v1.a.a;
									var _v9 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$mToYd(
												$elm$core$Basics$round(d)) / 100));
								case 2:
									var _v10 = _v1.a.a;
									var _v11 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$miToYd(d) / 100));
								default:
									var _v14 = _v1.a.a;
									var _v15 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$pace, t, d / 100));
							}
						case 2:
							switch (_v1.a.a) {
								case 0:
									var _v2 = _v1.a.a;
									var _v3 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$kmToM(d) / 100));
								case 1:
									var _v4 = _v1.a.a;
									var _v5 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$pace, t, d / 100));
								case 2:
									var _v12 = _v1.a.a;
									var _v13 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$miToM(d) / 100));
								default:
									var _v16 = _v1.a.a;
									var _v17 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingPace$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$pace,
											t,
											$author$project$Elm$Service$Calculator$ydToM(
												$elm$core$Basics$round(d)) / 100));
							}
						default:
							break _v1$8;
					}
				} else {
					break _v1$8;
				}
			}
			return $author$project$Elm$Page$SwimmingPace$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$SwimmingPace$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$SwimmingPace$db = 'swimming-pace';
var $author$project$Elm$Page$SwimmingPace$Storage = F5(
	function (distance, distanceUnit, time, resultUnit, isCalculated) {
		return {g: distance, V: distanceUnit, aZ: isCalculated, aa: resultUnit, n: time};
	});
var $author$project$Elm$Page$SwimmingPace$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$SwimmingPace$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distance']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['time']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['resultUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$SwimmingPace$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'distance',
				$elm$json$Json$Encode$string(storage.g)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$string(storage.n)),
				_Utils_Tuple2(
				'resultUnit',
				$elm$json$Json$Encode$int(storage.aa)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$SwimmingPace$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingPace$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$SwimmingPace$validate = F2(
	function (distance, time) {
		var _v0 = _Utils_Tuple2(distance.an, time.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$SwimmingPace$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$SwimmingPace$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('distance'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$SwimmingPace$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('time'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(distance.E),
					!$elm$core$String$isEmpty(time.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$SwimmingPace$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('pace'));
					} else {
						return $author$project$Elm$Page$SwimmingPace$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('distance'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$SwimmingPace$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('time'));
					} else {
						return $author$project$Elm$Page$SwimmingPace$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$SwimmingPace$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.g);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Self, $author$project$Elm$Page$SwimmingPace$DistanceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Parent, $author$project$Elm$Page$SwimmingPace$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingPace$Self($author$project$Elm$Page$SwimmingPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.n);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{n: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Self, $author$project$Elm$Page$SwimmingPace$TimeInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Parent, $author$project$Elm$Page$SwimmingPace$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingPace$Self($author$project$Elm$Page$SwimmingPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Self, $author$project$Elm$Page$SwimmingPace$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$SwimmingPace$Self($author$project$Elm$Page$SwimmingPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$SwimmingPace$validate, model.g, model.n);
				var success = function (_int) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$author$project$Elm$Service$Converter$secToPace(_int),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingPace$Parent($author$project$Elm$Page$SwimmingPace$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$SwimmingPace$db,
										$author$project$Elm$Page$SwimmingPace$storageEncoder(
											{
												g: model.g.E,
												V: $author$project$Elm$Service$Unit$toId(model.g.t),
												aZ: true,
												aa: $author$project$Elm$Service$Unit$toId(model.bj.t),
												n: model.n.E
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Parent, $author$project$Elm$Page$SwimmingPace$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A3($author$project$Elm$Page$SwimmingPace$calculate, model.g, model.n, model.bj);
					if (calculation.$ === 1) {
						var sec = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(sec) ? success(sec) : error(
							$author$project$Elm$Service$Validator$outOfRange('pace'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A2($author$project$Elm$Element$Input$setValue, '', model.g),
							aZ: false,
							bj: $author$project$Elm$Element$Result$clearValue(model.bj),
							n: A2($author$project$Elm$Element$Input$setValue, '', model.n)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$SwimmingPace$db,
							$author$project$Elm$Page$SwimmingPace$storageEncoder(
								{
									g: '',
									V: $author$project$Elm$Service$Unit$toId(model.g.t),
									aZ: false,
									aa: $author$project$Elm$Service$Unit$toId(model.bj.t),
									n: ''
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$SwimmingPace$storageDecoder, value);
				var _v9 = model;
				var distance = _v9.g;
				var time = _v9.n;
				var result = _v9.bj;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedTimeInput = _Utils_update(
						time,
						{E: storage.n});
					var updatedResult = _Utils_update(
						result,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.aa)
						});
					var updatedDistanceInput = _Utils_update(
						distance,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V),
							E: storage.g
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedDistanceInput, bj: updatedResult, n: updatedTimeInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$SwimmingPace$Self($author$project$Elm$Page$SwimmingPace$CalculatePace)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$SwimmingPace$db));
		}
	});
var $author$project$Elm$Page$SwimmingTime$CalculateTime = {$: 3};
var $author$project$Elm$Page$SwimmingTime$DistanceInputMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingTime$HideSnackbar = {$: 1};
var $author$project$Elm$Page$SwimmingTime$PaceInputMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingTime$Parent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingTime$ResultMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Elm$Page$SwimmingTime$ShowSnackbar = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingTime$CalculationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingTime$CalculationSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Elm$Page$SwimmingTime$calculate = F2(
	function (distance, pace) {
		var _v0 = _Utils_Tuple2(
			$elm$core$String$toFloat(distance.E),
			$author$project$Elm$Service$Converter$paceToSec(pace.E));
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var d = _v0.a.a;
			var p = _v0.b.a;
			var _v1 = _Utils_Tuple2(distance.t, pace.t);
			_v1$8:
			while (true) {
				if ((!_v1.a.$) && (_v1.b.$ === 2)) {
					switch (_v1.b.a) {
						case 3:
							switch (_v1.a.a) {
								case 0:
									var _v6 = _v1.a.a;
									var _v7 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$kmToYd(d) / 100,
											p));
								case 1:
									var _v8 = _v1.a.a;
									var _v9 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$mToYd(
												$elm$core$Basics$round(d)) / 100,
											p));
								case 2:
									var _v10 = _v1.a.a;
									var _v11 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$miToYd(d) / 100,
											p));
								default:
									var _v14 = _v1.a.a;
									var _v15 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$timeByPace, d / 100, p));
							}
						case 2:
							switch (_v1.a.a) {
								case 0:
									var _v2 = _v1.a.a;
									var _v3 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$kmToM(d) / 100,
											p));
								case 1:
									var _v4 = _v1.a.a;
									var _v5 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2($author$project$Elm$Service$Calculator$timeByPace, d / 100, p));
								case 2:
									var _v12 = _v1.a.a;
									var _v13 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$miToM(d) / 100,
											p));
								default:
									var _v16 = _v1.a.a;
									var _v17 = _v1.b.a;
									return $author$project$Elm$Page$SwimmingTime$CalculationSuccess(
										A2(
											$author$project$Elm$Service$Calculator$timeByPace,
											$author$project$Elm$Service$Calculator$ydToM(
												$elm$core$Basics$round(d)) / 100,
											p));
							}
						default:
							break _v1$8;
					}
				} else {
					break _v1$8;
				}
			}
			return $author$project$Elm$Page$SwimmingTime$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		} else {
			return $author$project$Elm$Page$SwimmingTime$CalculationError($author$project$Elm$Service$Validator$unhandledException);
		}
	});
var $author$project$Elm$Page$SwimmingTime$db = 'swimming-time';
var $author$project$Elm$Page$SwimmingTime$Storage = F5(
	function (distance, distanceUnit, pace, paceUnit, isCalculated) {
		return {g: distance, V: distanceUnit, aZ: isCalculated, i: pace, _: paceUnit};
	});
var $author$project$Elm$Page$SwimmingTime$storageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Elm$Page$SwimmingTime$Storage,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distance']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['distanceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['pace']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['paceUnit']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['isCalculated']),
		$elm$json$Json$Decode$bool));
var $author$project$Elm$Page$SwimmingTime$storageEncoder = function (storage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'distance',
				$elm$json$Json$Encode$string(storage.g)),
				_Utils_Tuple2(
				'distanceUnit',
				$elm$json$Json$Encode$int(storage.V)),
				_Utils_Tuple2(
				'pace',
				$elm$json$Json$Encode$string(storage.i)),
				_Utils_Tuple2(
				'paceUnit',
				$elm$json$Json$Encode$int(storage._)),
				_Utils_Tuple2(
				'isCalculated',
				$elm$json$Json$Encode$bool(storage.aZ))
			]));
};
var $author$project$Elm$Page$SwimmingTime$ValidationError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Page$SwimmingTime$ValidationSuccess = {$: 1};
var $author$project$Elm$Page$SwimmingTime$validate = F2(
	function (distance, pace) {
		var _v0 = _Utils_Tuple2(distance.an, pace.an);
		if (!_v0.a) {
			if (!_v0.b) {
				return $author$project$Elm$Page$SwimmingTime$ValidationError($author$project$Elm$Service$Validator$fieldsNotValid);
			} else {
				return $author$project$Elm$Page$SwimmingTime$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('distance'));
			}
		} else {
			if (!_v0.b) {
				return $author$project$Elm$Page$SwimmingTime$ValidationError(
					$author$project$Elm$Service$Validator$fieldNotValid('pace'));
			} else {
				var _v1 = _Utils_Tuple2(
					!$elm$core$String$isEmpty(distance.E),
					!$elm$core$String$isEmpty(pace.E));
				if (!_v1.a) {
					if (!_v1.b) {
						return $author$project$Elm$Page$SwimmingTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldsEmpty('time'));
					} else {
						return $author$project$Elm$Page$SwimmingTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('distance'));
					}
				} else {
					if (!_v1.b) {
						return $author$project$Elm$Page$SwimmingTime$ValidationError(
							$author$project$Elm$Service$Validator$fieldEmpty('pace'));
					} else {
						return $author$project$Elm$Page$SwimmingTime$ValidationSuccess;
					}
				}
			}
		}
	});
var $author$project$Elm$Page$SwimmingTime$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v1 = A2($author$project$Elm$Element$Input$update, subMsg, model.g);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Self, $author$project$Elm$Page$SwimmingTime$DistanceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Parent, $author$project$Elm$Page$SwimmingTime$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingTime$Self($author$project$Elm$Page$SwimmingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 1:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Element$Input$update, subMsg, model.i);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{i: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Self, $author$project$Elm$Page$SwimmingTime$PaceInputMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					switch (subMsg.$) {
						case 0:
							var message = subMsg.a;
							return _Utils_Tuple2(
								model,
								$author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Parent, $author$project$Elm$Page$SwimmingTime$ShowSnackbar)(message)));
						case 1:
							return model.aZ ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aZ: false,
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bj: $author$project$Elm$Element$Result$clearValue(model.bj)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(
								model,
								model.aZ ? $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingTime$Self($author$project$Elm$Page$SwimmingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
					}
				}
			case 2:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v5 = A2($author$project$Elm$Element$Result$update, subMsg, model.bj);
					var updatedModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: updatedModel}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Self, $author$project$Elm$Page$SwimmingTime$ResultMsg),
							cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						model.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$SwimmingTime$Self($author$project$Elm$Page$SwimmingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
				}
			case 3:
				var validation = A2($author$project$Elm$Page$SwimmingTime$validate, model.g, model.i);
				var success = function (_int) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: true,
								bj: A2(
									$author$project$Elm$Element$Result$setValue,
									$author$project$Elm$Service$Converter$secToTime(_int),
									model.bj)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Page$SwimmingTime$Parent($author$project$Elm$Page$SwimmingTime$HideSnackbar)),
									$author$project$Elm$Port$saveToLocalStorage(
									_Utils_Tuple2(
										$author$project$Elm$Page$SwimmingTime$db,
										$author$project$Elm$Page$SwimmingTime$storageEncoder(
											{
												g: model.g.E,
												V: $author$project$Elm$Service$Unit$toId(model.g.t),
												aZ: true,
												i: model.i.E,
												_: $author$project$Elm$Service$Unit$toId(model.i.t)
											})))
								])));
				};
				var error = function (message) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: false,
								bj: $author$project$Elm$Element$Result$clearValue(model.bj)
							}),
						$author$project$Elm$Util$Cmd$fire(
							A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Parent, $author$project$Elm$Page$SwimmingTime$ShowSnackbar)(message)));
				};
				if (!validation.$) {
					var message = validation.a;
					return error(message);
				} else {
					var calculation = A2($author$project$Elm$Page$SwimmingTime$calculate, model.g, model.i);
					if (calculation.$ === 1) {
						var sec = calculation.a;
						return $elm_community$basics_extra$Basics$Extra$isSafeInteger(sec) ? success(sec) : error(
							$author$project$Elm$Service$Validator$outOfRange('time'));
					} else {
						var message = calculation.a;
						return error(message);
					}
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g: A2($author$project$Elm$Element$Input$setValue, '', model.g),
							aZ: false,
							i: A2($author$project$Elm$Element$Input$setValue, '', model.i),
							bj: $author$project$Elm$Element$Result$clearValue(model.bj)
						}),
					$author$project$Elm$Port$saveToLocalStorage(
						_Utils_Tuple2(
							$author$project$Elm$Page$SwimmingTime$db,
							$author$project$Elm$Page$SwimmingTime$storageEncoder(
								{
									g: '',
									V: $author$project$Elm$Service$Unit$toId(model.g.t),
									aZ: false,
									i: '',
									_: $author$project$Elm$Service$Unit$toId(model.i.t)
								}))));
			case 5:
				var value = msg.a;
				var decodeStorage = A2($elm$json$Json$Decode$decodeValue, $author$project$Elm$Page$SwimmingTime$storageDecoder, value);
				var _v9 = model;
				var distance = _v9.g;
				var pace = _v9.i;
				if (!decodeStorage.$) {
					var storage = decodeStorage.a;
					var updatedPaceInput = _Utils_update(
						pace,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage._),
							E: storage.i
						});
					var updatedDistanceInput = _Utils_update(
						distance,
						{
							t: $author$project$Elm$Service$Unit$fromId(storage.V),
							E: storage.g
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: updatedDistanceInput, i: updatedPaceInput}),
						storage.aZ ? $author$project$Elm$Util$Cmd$fire(
							$author$project$Elm$Page$SwimmingTime$Self($author$project$Elm$Page$SwimmingTime$CalculateTime)) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Elm$Port$getFromLocalStorage($author$project$Elm$Page$SwimmingTime$db));
		}
	});
var $author$project$Elm$Route$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{X: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{X: false}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				var subMsg = msg.a;
				var _v1 = model.d;
				if (_v1.$ === 1) {
					var subModel = _v1.a;
					var _v2 = A2($author$project$Elm$Page$Home$update, subMsg, subModel);
					var updatedModel = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Elm$Route$HomeModel(updatedModel)
							}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$HomeMsg),
							cmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = model.d;
					if (_v3.$ === 2) {
						var subModel = _v3.a;
						var _v4 = A2($author$project$Elm$Page$RunningPace$update, subMsg, subModel);
						var updatedModel = _v4.a;
						var cmd = _v4.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$RunningPaceModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningPaceMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 6:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v6 = model.d;
					if (_v6.$ === 3) {
						var subModel = _v6.a;
						var _v7 = A2($author$project$Elm$Page$RunningTime$update, subMsg, subModel);
						var updatedModel = _v7.a;
						var cmd = _v7.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$RunningTimeModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningTimeMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 7:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v9 = model.d;
					if (_v9.$ === 4) {
						var subModel = _v9.a;
						var _v10 = A2($author$project$Elm$Page$RunningDistance$update, subMsg, subModel);
						var updatedModel = _v10.a;
						var cmd = _v10.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$RunningDistanceModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningDistanceMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 8:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v12 = model.d;
					if (_v12.$ === 5) {
						var subModel = _v12.a;
						var _v13 = A2($author$project$Elm$Page$CyclingSpeed$update, subMsg, subModel);
						var updatedModel = _v13.a;
						var cmd = _v13.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$CyclingSpeedModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingSpeedMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 9:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v15 = model.d;
					if (_v15.$ === 6) {
						var subModel = _v15.a;
						var _v16 = A2($author$project$Elm$Page$CyclingTime$update, subMsg, subModel);
						var updatedModel = _v16.a;
						var cmd = _v16.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$CyclingTimeModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingTimeMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 10:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v18 = model.d;
					if (_v18.$ === 7) {
						var subModel = _v18.a;
						var _v19 = A2($author$project$Elm$Page$CyclingDistance$update, subMsg, subModel);
						var updatedModel = _v19.a;
						var cmd = _v19.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$CyclingDistanceModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingDistanceMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 11:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v21 = model.d;
					if (_v21.$ === 8) {
						var subModel = _v21.a;
						var _v22 = A2($author$project$Elm$Page$SwimmingPace$update, subMsg, subModel);
						var updatedModel = _v22.a;
						var cmd = _v22.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$SwimmingPaceModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingPaceMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 12:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v24 = model.d;
					if (_v24.$ === 9) {
						var subModel = _v24.a;
						var _v25 = A2($author$project$Elm$Page$SwimmingTime$update, subMsg, subModel);
						var updatedModel = _v25.a;
						var cmd = _v25.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$SwimmingTimeModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingTimeMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 13:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v27 = model.d;
					if (_v27.$ === 10) {
						var subModel = _v27.a;
						var _v28 = A2($author$project$Elm$Page$SwimmingDistance$update, subMsg, subModel);
						var updatedModel = _v28.a;
						var cmd = _v28.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Elm$Route$SwimmingDistanceModel(updatedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingDistanceMsg),
								cmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							if (!subMsg.$) {
								var message = subMsg.a;
								return $author$project$Elm$Util$Cmd$fire(
									A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ShowSnackbar)(message));
							} else {
								return $author$project$Elm$Util$Cmd$fire(
									$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar));
							}
						}());
				}
			case 14:
				var subMsg = msg.a;
				var _v30 = model.d;
				if (_v30.$ === 11) {
					var subModel = _v30.a;
					var _v31 = A2($author$project$Elm$Page$Contact$update, subMsg, subModel);
					var updatedModel = _v31.a;
					var cmd = _v31.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Elm$Route$ContactModel(updatedModel)
							}),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ContactMsg),
							cmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 15:
				var message = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: _Utils_Tuple2('true', message)
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: _Utils_Tuple2('false', model.R.b)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ak: true}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.ag,
							$elm$url$Url$toString(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 2:
				var url = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ay: url}),
					$author$project$Elm$Util$Cmd$fire(
						$author$project$Main$ChangePage(url)));
			case 4:
				var url = msg.a;
				var _v2 = $author$project$Elm$Route$init(url);
				var routeModel = _v2.a;
				var routeCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{N: routeModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$RouteMsg, routeCmd));
			default:
				if (!msg.a.$) {
					var subMsg = msg.a.a;
					var _v3 = A2($author$project$Elm$Route$update, subMsg, model.N);
					var updatedModel = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{N: updatedModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$RouteMsg, cmd));
				} else {
					var subMsg = msg.a.a;
					return _Utils_Tuple2(
						model,
						function () {
							var url = subMsg;
							return A2($elm$browser$Browser$Navigation$pushUrl, model.ag, url);
						}());
				}
		}
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Elm$Route$NotFoundMsg = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Elm$Page$Contact$ShowEmail = 0;
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Elm$Page$Contact$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('contact-page')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('contact-page__content')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Contact us')
							])),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('\n                    If you have any advice for improving the functionality or usability,\n                    please drop us a line by\n                    '),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('contact-page__link'),
										$elm$html$Html$Events$onClick(0)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('email')
									])),
								$elm$html$Html$text('.'),
								$elm$html$Html$text('\n                    We will be very glad to hear your suggestions for adding new calculators.\n                    Also, if you notice a bug please let us know with the attached screenshots\n                    or a detailed description of the problem.\n                    Thanks! May the speed be with you! 🏃\n                    ')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('contact-page__contact'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'is-visible',
										!$elm$core$String$isEmpty(model))
									]))
							]),
						_List_fromArray(
							[
								A3(
								$elm$html$Html$node,
								'custom-mwc-button',
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('contact-page__button'),
										A2($elm$html$Html$Attributes$attribute, 'outlined', ''),
										A2($elm$html$Html$Attributes$attribute, 'dense', ''),
										A2($elm$html$Html$Attributes$attribute, 'label', 'Show email'),
										$elm$html$Html$Events$onClick(0)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('contact-page__email mdc-typography mdc-typography--body1'),
										$elm$html$Html$Attributes$href('mailto:' + model)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(model)
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('contact-page__image')
					]),
				_List_Nil)
			]));
};
var $author$project$Elm$Page$CyclingDistance$ResetForm = {$: 4};
var $author$project$Elm$Service$Validator$floatRegex = '^\\d+(\\.\\d+)?$';
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Elm$Service$Validator$timeError = 'Wrong value. Please make sure you added leading zeros and the value in HH:MM:SS format (e.g. 03:59:53)';
var $author$project$Elm$Service$Validator$timeHint = 'Must follow HH:MM:SS pattern (e.g. 02:01:59)';
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Elm$Element$Input$ChangeValidity = function (a) {
	return {$: 3, a: a};
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $author$project$Elm$Element$Input$UpdateValue = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Elm$Element$Input$viewLink = function (_v0) {
	var name = _v0.Q;
	var unit = _v0.t;
	var value = _v0.E;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('input-element__item')
			]),
		_List_fromArray(
			[
				A3(
				$elm$html$Html$node,
				'custom-mwc-button',
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'outlined', ''),
						A2($elm$html$Html$Attributes$attribute, 'dense', ''),
						A2($elm$html$Html$Attributes$attribute, 'label', name),
						$elm$html$Html$Events$onClick(
						$author$project$Elm$Element$Input$Self(
							A2($author$project$Elm$Element$Input$UpdateValue, unit, value)))
					]),
				_List_Nil)
			]));
};
var $author$project$Elm$Element$Input$ChangeUnit = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $author$project$Elm$Element$Input$viewUnit = F3(
	function (_v0, group, isChecked) {
		var unit = _v0.t;
		var name = _v0.Q;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('input-element__item')
				]),
			_List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'mwc-formfield',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'label', name)
						]),
					_List_fromArray(
						[
							A3(
							$elm$html$Html$node,
							'mwc-radio',
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'name', group),
									$elm$html$Html$Attributes$checked(isChecked),
									$elm$html$Html$Events$onCheck(
									function (_v1) {
										return A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Self, $author$project$Elm$Element$Input$ChangeUnit)(unit);
									})
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Elm$Element$Input$view = F2(
	function (_v0, model) {
		var name = _v0.Q;
		var units = _v0.bA;
		var links = _v0.a1;
		var activeUnitData = F3(
			function (fn, attr, _default) {
				return A2(
					$elm$html$Html$Attributes$attribute,
					attr,
					A2(
						$elm$core$Maybe$withDefault,
						_default,
						A2(
							$elm$core$Maybe$map,
							fn,
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (ud) {
										return _Utils_eq(ud.t, model.t);
									},
									units.b)))));
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('input-element')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('input-element__field')
						]),
					_List_fromArray(
						[
							A3(
							$elm$html$Html$node,
							'custom-mwc-textfield',
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'outlined', ''),
									A2($elm$html$Html$Attributes$attribute, 'label', name),
									A2($elm$html$Html$Attributes$attribute, 'icontrailing', 'delete'),
									A2($elm$html$Html$Attributes$attribute, 'value', model.E),
									A3(
									activeUnitData,
									function ($) {
										return $.aK;
									},
									'validationmessage',
									'Not valid'),
									A3(
									activeUnitData,
									function ($) {
										return $.bc;
									},
									'regex',
									''),
									A3(
									activeUnitData,
									function ($) {
										return $.aV;
									},
									'helper',
									''),
									A3(
									activeUnitData,
									function ($) {
										return $.bp;
									},
									'unit',
									''),
									$elm$html$Html$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Self, $author$project$Elm$Element$Input$ChangeValue)),
									A2(
									$elm$html$Html$Events$on,
									'MDCTextfield:delete',
									$elm$json$Json$Decode$succeed(
										A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Self, $author$project$Elm$Element$Input$ChangeValue)(''))),
									A2(
									$elm$html$Html$Events$on,
									'MDCTextfield:validated',
									A2(
										$elm$json$Json$Decode$map,
										A2($elm$core$Basics$composeL, $author$project$Elm$Element$Input$Self, $author$project$Elm$Element$Input$ChangeValidity),
										A2($elm$json$Json$Decode$field, 'detail', $elm$json$Json$Decode$bool)))
								]),
							_List_Nil)
						])),
					function () {
					var _v1 = units.b;
					if (!_v1.b) {
						return $elm$html$Html$text('');
					} else {
						if (!_v1.b.b) {
							return $elm$html$Html$text('');
						} else {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('input-element__scroller')
									]),
								A2(
									$elm$core$List$map,
									function (u) {
										return A3(
											$author$project$Elm$Element$Input$viewUnit,
											u,
											units.a,
											_Utils_eq(u.t, model.t));
									},
									units.b));
						}
					}
				}(),
					function () {
					if (!links.b) {
						return $elm$html$Html$text('');
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('input-element__scroller')
								]),
							A2($elm$core$List$map, $author$project$Elm$Element$Input$viewLink, links));
					}
				}()
				]));
	});
var $author$project$Elm$Element$Result$ChangeUnit = $elm$core$Basics$identity;
var $author$project$Elm$Element$Result$Self = function (a) {
	return {$: 0, a: a};
};
var $author$project$Elm$Element$Result$viewUnit = F3(
	function (_v0, group, isChecked) {
		var unit = _v0.t;
		var name = _v0.Q;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('result-element__item')
				]),
			_List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'mwc-formfield',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'label', name)
						]),
					_List_fromArray(
						[
							A3(
							$elm$html$Html$node,
							'mwc-radio',
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'name', group),
									$elm$html$Html$Attributes$checked(isChecked),
									$elm$html$Html$Events$onCheck(
									function (_v1) {
										return A2($elm$core$Basics$composeL, $author$project$Elm$Element$Result$Self, $elm$core$Basics$identity)(unit);
									})
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Elm$Element$Result$view = F2(
	function (_v0, model) {
		var title = _v0.bx;
		var units = _v0.bA;
		var activeUnitData = A2(
			$elm$core$List$filter,
			function (ud) {
				return _Utils_eq(ud.t, model.t);
			},
			units.b);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('result-element')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('result-element__main')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('result-element__title mdc-typography--headline4')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('result-element__value mdc-typography--headline3'),
									A2($elm$html$Html$Attributes$attribute, 'title', model.E)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(model.E)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('result-element__shortcut mdc-typography--headline5')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											function (ud) {
												return '(' + (ud.bp + ')');
											},
											$elm$core$List$head(activeUnitData))))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('result-element__scroller')
						]),
					_List_fromArray(
						[
							function () {
							var _v1 = units.b;
							if (!_v1.b) {
								return $elm$html$Html$text('');
							} else {
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('result-element__scroller')
										]),
									A2(
										$elm$core$List$map,
										function (u) {
											return A3(
												$author$project$Elm$Element$Result$viewUnit,
												u,
												units.a,
												_Utils_eq(u.t, model.t));
										},
										units.b));
							}
						}()
						]))
				]));
	});
var $author$project$Elm$Layout$Page$view = function (_v0) {
	var form = _v0.aO;
	var result = _v0.bj;
	var calculate = _v0.aD;
	var reset = _v0.bh;
	var description = _v0.aI;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('page-layout')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('page-layout__side page-layout__side-left')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('page-layout__form')
							]),
						form),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('page-layout__buttons')
							]),
						_List_fromArray(
							[
								A3(
								$elm$html$Html$node,
								'custom-mwc-button',
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('page-layout__button'),
										A2($elm$html$Html$Attributes$attribute, 'label', 'Reset'),
										$elm$html$Html$Events$onClick(reset)
									]),
								_List_Nil),
								A3(
								$elm$html$Html$node,
								'custom-mwc-button',
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('page-layout__button page-layout__button_calculate'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('is-calculated', calculate.aZ)
											])),
										A2($elm$html$Html$Attributes$attribute, 'raised', ''),
										A2(
										$elm$html$Html$Attributes$attribute,
										'label',
										calculate.aZ ? 'Calculated' : 'Calculate'),
										$elm$html$Html$Events$onClick(calculate.b2)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('page-layout__result')
							]),
						result)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('page-layout__side page-layout__side-right')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('page-layout__description')
							]),
						description)
					]))
			]));
};
var $author$project$Elm$Page$CyclingDistance$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Self, $author$project$Elm$Page$CyclingDistance$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Distance is',
					bA: _Utils_Tuple2(
						'cycling-distance-result',
						_List_fromArray(
							[
								{
								Q: 'Kilometers',
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								Q: 'Miles',
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							}
							]))
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Self, $author$project$Elm$Page$CyclingDistance$TimeInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Cycling time',
					bA: _Utils_Tuple2(
						'cycling-distance-time',
						_List_fromArray(
							[
								{aK: $author$project$Elm$Service$Validator$timeError, aV: $author$project$Elm$Service$Validator$timeHint, Q: '', bc: $author$project$Elm$Service$Validator$timeRegex, bp: '', t: $author$project$Elm$Service$Unit$Time}
							]))
				},
				model.n)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingDistance$Self, $author$project$Elm$Page$CyclingDistance$SpeedInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Cycling speed',
					bA: _Utils_Tuple2(
						'cycling-distance-speed',
						_List_fromArray(
							[
								{
								aK: 'Wrong value, please make sure you value is float or integer number e.g. 18',
								aV: 'Must be a number, e.g 33.5',
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km/h',
								t: $author$project$Elm$Service$Unit$Speed(0)
							},
								{
								aK: 'Wrong value, please make sure you value is float or integer number e.g. 18',
								aV: 'Must be a number, e.g 33.5',
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi/h',
								t: $author$project$Elm$Service$Unit$Speed(1)
							}
							]))
				},
				model.j))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Get the distance')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    The distance is calculated by multiplying time by speed.\n                    Of course, with this calculator you can calculate not only the cycling distance,\n                    but also the running, swimming and so on (knowing your speed).\n                    But here we focus on the bike 🚴\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use cycling distance calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine how far you will be at your target average speed.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Find out the distance of your next training ride in order to choose the route in advance.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        One of the most prestigious records in cycling is an hour record.\n                        The cyclist must ride as far as possible in one hour.\n                        Cyclists attempt this record alone on the track without other\n                        competitors present. On 16 April 2019, Victor Campenaerts set the current record\n                        at 55.089 km (34.23 mi).\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        One year cycling distance record holds Amanda Coker.\n                        In 2017, she rode 86.573 mi (139.326 km) and overpass\n                        the previous record by over 10.000 mi  (16.093 km).\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$CyclingDistance$Self($author$project$Elm$Page$CyclingDistance$CalculateDistance)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$CyclingDistance$Self($author$project$Elm$Page$CyclingDistance$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Page$CyclingSpeed$ResetForm = {$: 4};
var $author$project$Elm$Service$Validator$floatError = 'Wrong value. Please make sure the value is an integer or a float (e.g. 42.195, 2000)';
var $author$project$Elm$Service$Validator$floatHint = 'Must be an integer or float (e.g. 21.5)';
var $author$project$Elm$Page$CyclingSpeed$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Self, $author$project$Elm$Page$CyclingSpeed$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Speed is',
					bA: _Utils_Tuple2(
						'cycling-speed-result',
						_List_fromArray(
							[
								{
								Q: 'Kilometers',
								bp: 'km/h',
								t: $author$project$Elm$Service$Unit$Speed(0)
							},
								{
								Q: 'Miles',
								bp: 'mi/h',
								t: $author$project$Elm$Service$Unit$Speed(1)
							}
							]))
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Self, $author$project$Elm$Page$CyclingSpeed$DistanceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_fromArray(
						[
							{
							Q: 'Alpe d\'Huez',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '13.2'
						},
							{
							Q: 'Paris - Roubaix',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '257'
						},
							{
							Q: 'Milan - San Remo',
							t: $author$project$Elm$Service$Unit$Distance(2),
							E: '185.2'
						}
						]),
					Q: 'Cycling distance',
					bA: _Utils_Tuple2(
						'cycling-speed-distance',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							}
							]))
				},
				model.g)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingSpeed$Self, $author$project$Elm$Page$CyclingSpeed$TimeInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Cycling time',
					bA: _Utils_Tuple2(
						'cycling-speed-time',
						_List_fromArray(
							[
								{aK: $author$project$Elm$Service$Validator$timeError, aV: $author$project$Elm$Service$Validator$timeHint, Q: '', bc: $author$project$Elm$Service$Validator$timeRegex, bp: '', t: $author$project$Elm$Service$Unit$Time}
							]))
				},
				model.n))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Get your speed')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    Of course, you can calculate not only the speed of cycling, but the bike is our focus.\n                    The average speed of cycling can reach 40 km per hour on a flat surface.\n                    On cycling competitions, the speed can be even greater since the aerodynamic\n                    effect of the peloton works there. Trying to reach your maximum speed do not forget about the traffic rules 😉\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use cycling speed calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine how fast you are on the last ride.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Find out the speed you should ride to get to work on time.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The fastest human to ride a bicycle over open ground is named Denise Mueller-Korenek,\n                        who rode a custom bike at an average of 184 miles per hour –\n                        shattering a world record that had stood since 1995.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The fastest Tour de France was ridden by Lance Armstrong 3592.5 km\n                        in 86 hours 15 minutes 02 seconds – at an average speed of 41.7 km/h (25.9 mi/h).\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The bike course on Ironman competition is 180 km long.\n                        The fastest athletes can ride it less than 4 hours with an average speed of 45 km/h.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$CyclingSpeed$Self($author$project$Elm$Page$CyclingSpeed$CalculateSpeed)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$CyclingSpeed$Self($author$project$Elm$Page$CyclingSpeed$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Page$CyclingTime$ResetForm = {$: 4};
var $author$project$Elm$Page$CyclingTime$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Self, $author$project$Elm$Page$CyclingTime$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Time is',
					bA: _Utils_Tuple2('', _List_Nil)
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Self, $author$project$Elm$Page$CyclingTime$DistanceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_fromArray(
						[
							{
							Q: 'Alpe d\'Huez',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '13.2'
						},
							{
							Q: 'Paris - Roubaix',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '257'
						},
							{
							Q: 'Milan - San Remo',
							t: $author$project$Elm$Service$Unit$Distance(2),
							E: '185.2'
						}
						]),
					Q: 'Cycling distance',
					bA: _Utils_Tuple2(
						'cycling-time-distance',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							}
							]))
				},
				model.g)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$CyclingTime$Self, $author$project$Elm$Page$CyclingTime$SpeedInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Cycling speed',
					bA: _Utils_Tuple2(
						'cycling-time-speed',
						_List_fromArray(
							[
								{
								aK: 'Wrong value, please make sure you value is float or integer number e.g. 18',
								aV: 'Must be a number, e.g 33.5',
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km/h',
								t: $author$project$Elm$Service$Unit$Speed(0)
							},
								{
								aK: 'Wrong value, please make sure you value is float or integer number e.g. 18',
								aV: 'Must be a number, e.g 33.5',
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi/h',
								t: $author$project$Elm$Service$Unit$Speed(1)
							}
							]))
				},
				model.j))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Get your time')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    Knowing the time will help you understand\n                    whether you can achieve the next goal, whether\n                    it will be a group race or a cycling part of Ironman.\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use cycling time calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine the time of your next training session.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine the time you need to get to the target place.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The Paris–Roubaix is a famous one-day professional men\'s bicycle road race in northern France.\n                        The road of this race is very tough, covered with paving stones and earthen plots.\n                        Punctures and falls occur very often. But still, the fastest time of this\n                        race is 5 hours 41 minutes 7 seconds for a distance of 257 km (159.7 mi).\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The average time to complete Ironman 140.6 is 12 hours and 35 minutes.\n                        180 km (112 mi) bike course takes approximately 6 hours 25 minutes.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$CyclingTime$Self($author$project$Elm$Page$CyclingTime$CalculateTime)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$CyclingTime$Self($author$project$Elm$Page$CyclingTime$ResetForm),
			bj: result
		});
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Elm$Page$Home$viewCard = F4(
	function (title, content, image, buttons) {
		var button = function (_v0) {
			var label = _v0.A;
			var link = _v0.B;
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('home-page__card-link'),
						$elm$html$Html$Attributes$href(link)
					]),
				_List_fromArray(
					[
						A3(
						$elm$html$Html$node,
						'custom-mwc-button',
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'label', label)
							]),
						_List_Nil)
					]));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-card home-page__card')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-card__media mdc-card__media--square home-page__card-media'),
							A2($elm$html$Html$Attributes$style, 'background-image', 'url(' + (image + ')'))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page__card-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline6 home-page__card-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body2 home-page__card-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(content)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-card__actions home-page__actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mdc-card__action-buttons home-page__card-buttons')
								]),
							A2($elm$core$List$map, button, buttons))
						]))
				]));
	});
var $author$project$Elm$Page$Home$view = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('home-page')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('home-page__cards')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Elm$Page$Home$viewCard,
						'Running',
						'What pace should be to run a marathon less than 4 hours? How much time take to run 100 meters? Calculate it here',
						'images/icons/running.svg',
						_List_fromArray(
							[
								{A: 'Pace', B: '/running/pace'},
								{A: 'Time', B: '/running/time'},
								{A: 'Distance', B: '/running/distance'}
							])),
						A4(
						$author$project$Elm$Page$Home$viewCard,
						'Cycling',
						'Does speed matter for you? Find out speed, time or distance for your next ride',
						'images/icons/cycling.svg',
						_List_fromArray(
							[
								{A: 'Speed', B: '/cycling/speed'},
								{A: 'Time', B: '/cycling/time'},
								{A: 'Distance', B: '/cycling/distance'}
							])),
						A4(
						$author$project$Elm$Page$Home$viewCard,
						'Swimming',
						'What pace to choose for your next swim session? Wanna know Michael Phelps pace? Get it here',
						'images/icons/swimming.svg',
						_List_fromArray(
							[
								{A: 'Pace', B: '/swimming/pace'},
								{A: 'Time', B: '/swimming/time'},
								{A: 'Distance', B: '/swimming/distance'}
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('home-page__description')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('\n                    Speed Matters app combines calculators for running, cycling and swimming.\n                    With their help, you can easily calculate the various metrics in these sports.\n                    Preparing for the first marathon distance, the next open water race or a long bike ride,\n                    you will most likely need to calculate the pace, speed, distance or time.\n                    For both the amateurs and the professional athletes,\n                    one of the most common routines is to calculate their sport metrics.\n                    If you want to plan your result in upcoming competitions or find out the right pace for a personal record,\n                    our app will help you with this.\n                    It will be convenient for building training plans in the triathlon, as well as for each discipline separately.\n                    ')
							]))
					]))
			]));
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Elm$Page$NotFound$view = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('not-found-page')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('not-found-page__icon material-icons')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('image_search')
					])),
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Page not found')
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('not-found-page__link'),
						$elm$html$Html$Attributes$href('/')
					]),
				_List_fromArray(
					[
						A3(
						$elm$html$Html$node,
						'custom-mwc-button',
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'label', 'Return to home page'),
								A2($elm$html$Html$Attributes$attribute, 'raised', '')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Elm$Page$RunningDistance$ResetForm = {$: 4};
var $author$project$Elm$Service$Validator$paceError = 'Wrong value. Please make sure you added leading zeros and the value in MM:SS format (e.g. 06:05)';
var $author$project$Elm$Service$Validator$paceHint = 'Must follow MM:SS pattern (e.g. 04:35)';
var $author$project$Elm$Page$RunningDistance$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Self, $author$project$Elm$Page$RunningDistance$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Distance is',
					bA: _Utils_Tuple2(
						'running-distance-result',
						_List_fromArray(
							[
								{
								Q: 'Kilometers',
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								Q: 'Meters',
								bp: 'm',
								t: $author$project$Elm$Service$Unit$Distance(1)
							},
								{
								Q: 'Miles',
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							},
								{
								Q: 'Yards',
								bp: 'yd',
								t: $author$project$Elm$Service$Unit$Distance(3)
							}
							]))
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Self, $author$project$Elm$Page$RunningDistance$TimeInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Running time',
					bA: _Utils_Tuple2(
						'running-distance-time',
						_List_fromArray(
							[
								{aK: $author$project$Elm$Service$Validator$timeError, aV: $author$project$Elm$Service$Validator$timeHint, Q: '', bc: $author$project$Elm$Service$Validator$timeRegex, bp: '', t: $author$project$Elm$Service$Unit$Time}
							]))
				},
				model.n)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningDistance$Self, $author$project$Elm$Page$RunningDistance$PaceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Running pace',
					bA: _Utils_Tuple2(
						'running-distance-pace',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per kilometer',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/km',
								t: $author$project$Elm$Service$Unit$Pace(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per mile',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/mi',
								t: $author$project$Elm$Service$Unit$Pace(1)
							}
							]))
				},
				model.i))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('What do you need to calculate running distance?')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    To calculate the distance you need the pace you ran at and the duration\n                    of your training run or race. The four most common distances for road running\n                    events are 5 km, 10 km, half marathon (21.1 km) and marathon (42.2 km).\n                    There are also events for athletes with a high endurance level called ultramarathons.\n                    The distances are 50 km (31 mi), 100 km (62.1 mi), 50 mi (80.5 km), and 100 mi,\n                    although many ultramarathons have other distances.\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use running distance calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine how far you can run at a certain pace.\n                        For example, find out will you reach marathon distance\n                        if your pace will be 5:00 min per km throughout 4 hours.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Find out what distance you need to run in your next training session\n                        in order to choose a suitable route in advance.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The Self-Transcendence 3100 Mile Race is one of the impressive running race events ever.\n                        Participants should run 4.989 km around just one district in New York.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        One form of an ultramarathon is in which a competitor runs as far as they can in 24 hours.\n                        The most enduring athletes will often run 200 km (124 mi) or more,\n                        depending on conditions, and the best can go beyond 270 km (168 mi).\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The world\'s toughest running race, the Badwater 135 is most known for its 135\n                        miles that include a jaunt through Death Valley in July under the scorching sun!\n                        The race starts in Death Valley and ends on Mount Whitney, covering three mountain\n                        ranges for a total of 14,600 feet of ascent and 6,100 feet of descent. But before,\n                        you must pass the qualification for this race that includes at least three running races\n                        of at least 100 miles in length and at least one of them should be completed in the last 13 months.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$RunningDistance$Self($author$project$Elm$Page$RunningDistance$CalculateDistance)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$RunningDistance$Self($author$project$Elm$Page$RunningDistance$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Page$RunningPace$ResetForm = {$: 4};
var $author$project$Elm$Service$Validator$intRegex = '^\\d+$';
var $author$project$Elm$Service$Validator$integerError = 'Wrong value. Please make sure the value is an integer (e.g. 55, 1000)';
var $author$project$Elm$Service$Validator$integerHint = 'Must be an integer (e.g. 42, 100)';
var $author$project$Elm$Page$RunningPace$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Self, $author$project$Elm$Page$RunningPace$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Pace is',
					bA: _Utils_Tuple2(
						'running-pace-result',
						_List_fromArray(
							[
								{
								Q: 'Per kilometer',
								bp: 'min/km',
								t: $author$project$Elm$Service$Unit$Pace(0)
							},
								{
								Q: 'Per mile',
								bp: 'min/mi',
								t: $author$project$Elm$Service$Unit$Pace(1)
							}
							]))
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Self, $author$project$Elm$Page$RunningPace$DistanceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_fromArray(
						[
							{
							Q: 'Half Marathon',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '21.098'
						},
							{
							Q: 'Marathon',
							t: $author$project$Elm$Service$Unit$Distance(1),
							E: '42195'
						}
						]),
					Q: 'Running distance',
					bA: _Utils_Tuple2(
						'running-pace-distance',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Meters',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'm',
								t: $author$project$Elm$Service$Unit$Distance(1)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							},
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Yards',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'yd',
								t: $author$project$Elm$Service$Unit$Distance(3)
							}
							]))
				},
				model.g)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningPace$Self, $author$project$Elm$Page$RunningPace$TimeInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Running time',
					bA: _Utils_Tuple2(
						'running-pace-time',
						_List_fromArray(
							[
								{aK: $author$project$Elm$Service$Validator$timeError, aV: $author$project$Elm$Service$Validator$timeHint, Q: '', bc: $author$project$Elm$Service$Validator$timeRegex, bp: '', t: $author$project$Elm$Service$Unit$Time}
							]))
				},
				model.n))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('What is the running pace?')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    Pace is the term that runners use for the way they measure their speed while running.\n                    While speed is generally measured as miles per hour or kilometers per hour,\n                    runners do not have the patience to wait a whole hour to know how fast they have been running.\n                    Instead, runners measure their speed in terms of minutes per mile or minutes per kilometer.\n                    This type of speed measurement is what runners call pace.\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use running pace calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine how fast your pace should be if you have a certain finish time for the next distance or race.\n                        For example, find out what pace you need to keep to run\n                        10 km less than 42 minutes or a sub 2:00 half marathon.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine what pace needed to run to break the world record for the marathon.\n                        The current world record time for men is 2 hours 1 minute and 39 seconds.\n                        Hope you will see your average pace of the last workout 😁\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The current world record time for marathon is 2 hours 1 minute and 39 seconds,\n                        set in the Berlin Marathon by Eliud Kipchoge of Kenya on 16 September 2018,\n                        an improvement of 1 minute 18 seconds over the previous record also set in the Berlin Marathon\n                        by Dennis Kipruto Kimetto, also of Kenya on 28 September 2014.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        On 13 April 2003, Paula Radcliffe from Great Britain set the new women\'s world record for a marathon.\n                        She ran the distance in 2 hours 15 minutes and 25 seconds. This record was held for 13 years\n                        until in 2019, Brigid Kosgei of Kenya running a marathon in 2 hours 14 minutes and 4 seconds.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        In 2011, the 100 years old Fauja Singh, finished the Toronto Waterfront Marathon,\n                        becoming the first centenarian ever to officially complete that distance. Singh finished the race\n                        with a time of 8 hours 11 minutes and 5 seconds,\n                        making him the oldest marathoner. Because Singh could not produce a birth certificate\n                        from rural 1911 Colonial India, the place of his birth, his age could not be verified\n                        and his record was not accepted by the official governing body World Masters Athletics.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$RunningPace$Self($author$project$Elm$Page$RunningPace$CalculatePace)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$RunningPace$Self($author$project$Elm$Page$RunningPace$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Page$RunningTime$ResetForm = {$: 4};
var $author$project$Elm$Page$RunningTime$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Self, $author$project$Elm$Page$RunningTime$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Time is',
					bA: _Utils_Tuple2('', _List_Nil)
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Self, $author$project$Elm$Page$RunningTime$DistanceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_fromArray(
						[
							{
							Q: 'Half Marathon',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '21.098'
						},
							{
							Q: 'Marathon',
							t: $author$project$Elm$Service$Unit$Distance(1),
							E: '42195'
						}
						]),
					Q: 'Running distance',
					bA: _Utils_Tuple2(
						'running-time-distance',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Meters',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'm',
								t: $author$project$Elm$Service$Unit$Distance(1)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							},
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Yards',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'yd',
								t: $author$project$Elm$Service$Unit$Distance(3)
							}
							]))
				},
				model.g)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$RunningTime$Self, $author$project$Elm$Page$RunningTime$PaceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Running pace',
					bA: _Utils_Tuple2(
						'running-time-pace',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per kilometer',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/km',
								t: $author$project$Elm$Service$Unit$Pace(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per mile',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/mi',
								t: $author$project$Elm$Service$Unit$Pace(1)
							}
							]))
				},
				model.i))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('How to calculate running time?')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    To calculate time you need the pace you ran at and the distance of your training run or race.\n                    The world`s average time to complete a marathon is around 5 hours.\n                    Most men finish a marathon in under 4 and a half hours. Most women finish in just under 5 hours.\n                    Elite runners can run the distance in about 2 hours.\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use running time calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Find out if you can run 10 miles in less than an hour\n                        if you will run at the pace of your last hard training session.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine the duration of your next training session.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Dean Karnazes is an extraordinary athlete with high endurance.\n                        One of his greatest achievements is running for 80 hours and 44 minutes\n                        without sleep in 2005. During the race, he run  560 km (350 mi).\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Japanese Nao Kazami is one of the outstanding personalities in running.\n                        On June 24, 2018, he broke a 100 km running record with the\n                        result of 6 hours 9 minutes 14 seconds.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$RunningTime$Self($author$project$Elm$Page$RunningTime$CalculateTime)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$RunningTime$Self($author$project$Elm$Page$RunningTime$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Page$SwimmingDistance$ResetForm = {$: 4};
var $author$project$Elm$Page$SwimmingDistance$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Self, $author$project$Elm$Page$SwimmingDistance$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Distance is',
					bA: _Utils_Tuple2(
						'swimming-distance-result',
						_List_fromArray(
							[
								{
								Q: 'Meters',
								bp: 'm',
								t: $author$project$Elm$Service$Unit$Distance(1)
							},
								{
								Q: 'Yards',
								bp: 'yd',
								t: $author$project$Elm$Service$Unit$Distance(3)
							},
								{
								Q: 'Kilometers',
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								Q: 'Miles',
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							}
							]))
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Self, $author$project$Elm$Page$SwimmingDistance$TimeInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Swimming time',
					bA: _Utils_Tuple2(
						'swimming-distance-time',
						_List_fromArray(
							[
								{aK: $author$project$Elm$Service$Validator$timeError, aV: $author$project$Elm$Service$Validator$timeHint, Q: '', bc: $author$project$Elm$Service$Validator$timeRegex, bp: '', t: $author$project$Elm$Service$Unit$Time}
							]))
				},
				model.n)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingDistance$Self, $author$project$Elm$Page$SwimmingDistance$PaceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Swimming pace',
					bA: _Utils_Tuple2(
						'swimming-distance-pace',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per meters',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/100m',
								t: $author$project$Elm$Service$Unit$Pace(2)
							},
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per yards',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/100yd',
								t: $author$project$Elm$Service$Unit$Pace(3)
							}
							]))
				},
				model.i))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Get swimming distance')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    To calculate the distance you need the pace you swim at and the duration\n                    of your session or competition. The most popular pool swimming distances are 50, 100, 200, 400, 800 and 1500 meters.\n                    Open water swimming distances can vary from 500 meters to such long-distances as crossing Gibraltar (10 miles),\n                    swimming across the English Channel (32 km).\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use swimming distance calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine the distance that you can swim in one hour with\n                        a light pace to find out what distance to choose\n                        for your first open water swimming event.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Predict your distance on the next swimming workout.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The Norseman Xtreme Triathlon is a long distance competition in very difficult conditions.\n                        The swimming distance is 3800 meters in extremely cold water, approximately 10 degrees.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        On 21 August 2018, Linda Ashmore from the United Kingdom became\n                        the oldest person who swam across the English Channel.\n                        Her birth date is 21 October 1946, she made the swim aged 71 years.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$SwimmingDistance$Self($author$project$Elm$Page$SwimmingDistance$CalculateDistance)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$SwimmingDistance$Self($author$project$Elm$Page$SwimmingDistance$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Page$SwimmingPace$ResetForm = {$: 4};
var $author$project$Elm$Page$SwimmingPace$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Self, $author$project$Elm$Page$SwimmingPace$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Pace is',
					bA: _Utils_Tuple2(
						'swimming-pace-result',
						_List_fromArray(
							[
								{
								Q: 'Per meters',
								bp: 'min/100m',
								t: $author$project$Elm$Service$Unit$Pace(2)
							},
								{
								Q: 'Per yards',
								bp: 'min/100yd',
								t: $author$project$Elm$Service$Unit$Pace(3)
							}
							]))
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Self, $author$project$Elm$Page$SwimmingPace$DistanceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_fromArray(
						[
							{
							Q: 'Bosphorus',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '6.5'
						},
							{
							Q: 'IM 70.3',
							t: $author$project$Elm$Service$Unit$Distance(1),
							E: '1900'
						},
							{
							Q: 'IM 140.6',
							t: $author$project$Elm$Service$Unit$Distance(1),
							E: '3800'
						}
						]),
					Q: 'Swimming distance',
					bA: _Utils_Tuple2(
						'swimming-pace-distance',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Meters',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'm',
								t: $author$project$Elm$Service$Unit$Distance(1)
							},
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Yards',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'yd',
								t: $author$project$Elm$Service$Unit$Distance(3)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							}
							]))
				},
				model.g)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingPace$Self, $author$project$Elm$Page$SwimmingPace$TimeInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Swimming time',
					bA: _Utils_Tuple2(
						'swimming-pace-time',
						_List_fromArray(
							[
								{aK: $author$project$Elm$Service$Validator$timeError, aV: $author$project$Elm$Service$Validator$timeHint, Q: '', bc: $author$project$Elm$Service$Validator$timeRegex, bp: '', t: $author$project$Elm$Service$Unit$Time}
							]))
				},
				model.n))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('What is the swimming pace?')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    The pace in swimming is usually called the time during which the swimmer\n                    swims 100 meters or 100 yards. The average swimming pace of a non-professional\n                    is usually 2 minutes 30 seconds per 100 meters,\n                    and professionals swim faster than 1 minute 30 seconds.\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use swimming pace calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine what speed you need to swim 1 km in less than 25 minutes.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine your pace for the next training session or competition.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The current world records in the men\'s 100 metres freestyle swimming is 46.91 seconds\n                        for the long distance (swimming pool 50 meters) and 44.94 seconds for the short distance (swimming pool 25 meters).\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        The longest swimming distance at the Olympic Games is 10 kilometers.\n                        This is an open water competition that the fastest swimmers overcome in 1 hour 50 minutes.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$SwimmingPace$Self($author$project$Elm$Page$SwimmingPace$CalculatePace)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$SwimmingPace$Self($author$project$Elm$Page$SwimmingPace$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Page$SwimmingTime$ResetForm = {$: 4};
var $author$project$Elm$Page$SwimmingTime$view = function (model) {
	var result = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Self, $author$project$Elm$Page$SwimmingTime$ResultMsg),
			A2(
				$author$project$Elm$Element$Result$view,
				{
					bx: 'Time is',
					bA: _Utils_Tuple2('', _List_Nil)
				},
				model.bj))
		]);
	var form = _List_fromArray(
		[
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Self, $author$project$Elm$Page$SwimmingTime$DistanceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_fromArray(
						[
							{
							Q: 'Bosphorus',
							t: $author$project$Elm$Service$Unit$Distance(0),
							E: '6.5'
						},
							{
							Q: 'IM 70.3',
							t: $author$project$Elm$Service$Unit$Distance(1),
							E: '1900'
						},
							{
							Q: 'IM 140.6',
							t: $author$project$Elm$Service$Unit$Distance(1),
							E: '3800'
						}
						]),
					Q: 'Swimming distance',
					bA: _Utils_Tuple2(
						'swimming-time-distance',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Meters',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'm',
								t: $author$project$Elm$Service$Unit$Distance(1)
							},
								{
								aK: $author$project$Elm$Service$Validator$integerError,
								aV: $author$project$Elm$Service$Validator$integerHint,
								Q: 'Yards',
								bc: $author$project$Elm$Service$Validator$intRegex,
								bp: 'yd',
								t: $author$project$Elm$Service$Unit$Distance(3)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Kilometers',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'km',
								t: $author$project$Elm$Service$Unit$Distance(0)
							},
								{
								aK: $author$project$Elm$Service$Validator$floatError,
								aV: $author$project$Elm$Service$Validator$floatHint,
								Q: 'Miles',
								bc: $author$project$Elm$Service$Validator$floatRegex,
								bp: 'mi',
								t: $author$project$Elm$Service$Unit$Distance(2)
							}
							]))
				},
				model.g)),
			A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Elm$Page$SwimmingTime$Self, $author$project$Elm$Page$SwimmingTime$PaceInputMsg),
			A2(
				$author$project$Elm$Element$Input$view,
				{
					a1: _List_Nil,
					Q: 'Swimming pace',
					bA: _Utils_Tuple2(
						'swimming-time-pace',
						_List_fromArray(
							[
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per meters',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/100m',
								t: $author$project$Elm$Service$Unit$Pace(2)
							},
								{
								aK: $author$project$Elm$Service$Validator$paceError,
								aV: $author$project$Elm$Service$Validator$paceHint,
								Q: 'Per yards',
								bc: $author$project$Elm$Service$Validator$paceRegex,
								bp: 'min/100yd',
								t: $author$project$Elm$Service$Unit$Pace(3)
							}
							]))
				},
				model.i))
		]);
	var description = _List_fromArray(
		[
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Get swimming time')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('\n                    Tracking time in swimming is very important.\n                    Most workout plans include interval workouts.\n                    Calculate time using our calculator.\n                    ')
				])),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try to use swimming time calculator to')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Determine the time of your next swimming session. Plan your intervals ahead.\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Predict your time for the Ironman swimming course.\n                        ')
						]))
				])),
			A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-typography mdc-typography--headline4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Interesting facts')
				])),
			A2(
			$elm$html$Html$ul,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Martin Strel is legendary long-distance swimmer. He is the only man in the world\n                        who has swum the 5 greatest rivers on the planet: the Amazon, Yangtze, Paraná, Mississippi, Danube.\n                        Just imagine the entire time of swimming, if to swam Mississippi River took him 68 days (3.885 km).\n                        ')
						])),
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-typography mdc-typography--body1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('\n                        Over two thousands of swimmers from up to 50 countries every year take part in Cross Continental Swim\n                        in Bosphorus waterway. Bosphorus forms part of the continental boundary between Europe and Asia.\n                        The course record was set in 2006 with a time of 39 minutes 7 second.\n                        ')
						]))
				]))
		]);
	return $author$project$Elm$Layout$Page$view(
		{
			aD: {
				aZ: model.aZ,
				b2: $author$project$Elm$Page$SwimmingTime$Self($author$project$Elm$Page$SwimmingTime$CalculateTime)
			},
			aI: description,
			aO: form,
			bh: $author$project$Elm$Page$SwimmingTime$Self($author$project$Elm$Page$SwimmingTime$ResetForm),
			bj: result
		});
};
var $author$project$Elm$Route$ChangePage = $elm$core$Basics$identity;
var $author$project$Elm$Route$CloseNav = {$: 2};
var $author$project$Elm$Route$OpenNav = {$: 1};
var $author$project$Elm$Route$Parent = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Elm$Route$routeFromContent = function (content) {
	switch (content.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		case 9:
			return 9;
		case 10:
			return 10;
		default:
			return 11;
	}
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Elm$Route$viewLink = F4(
	function (path, name, icon, isActive) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('route__link'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-active', isActive)
						])),
					$elm$html$Html$Attributes$href(path)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(icon)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(path)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(name)
						]))
				]));
	});
var $author$project$Elm$Route$viewNav = F3(
	function (_v0, title, pageHtml) {
		var nav = _v0.X;
		var _return = _v0.as;
		var snackbar = _v0.R;
		var content = _v0.d;
		var visible = _v0.ak;
		var currentRoute = $author$project$Elm$Route$routeFromContent(content);
		return A3(
			$elm$html$Html$node,
			'mwc-drawer',
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('mwc-drawer'),
						A2($elm$html$Html$Attributes$attribute, 'hasheader', ''),
						$elm$html$Html$Attributes$type_('modal'),
						A2(
						$elm$html$Html$Events$on,
						'MDCDrawer:closed',
						$elm$json$Json$Decode$succeed(
							$author$project$Elm$Route$Self($author$project$Elm$Route$CloseNav)))
					]),
				nav ? _List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'open', '')
					]) : _List_Nil),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'slot', 'title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Menu')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'slot', 'subtitle')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('/contact'),
									$elm$html$Html$Attributes$class('route__contact')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Contact us')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('route__menu')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route__group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route__group-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Running')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route__group-links')
										]),
									_List_fromArray(
										[
											A4($author$project$Elm$Route$viewLink, '/running/pace', 'Pace', 'timer', currentRoute === 2),
											A4($author$project$Elm$Route$viewLink, '/running/time', 'Time', 'access_time', currentRoute === 3),
											A4($author$project$Elm$Route$viewLink, '/running/distance', 'Distance', 'trending_flat', currentRoute === 4)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route__divider')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route__group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route__group-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cycling')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route__group-links')
										]),
									_List_fromArray(
										[
											A4($author$project$Elm$Route$viewLink, '/cycling/speed', 'Speed', 'slow_motion_video', false),
											A4($author$project$Elm$Route$viewLink, '/cycling/time', 'Time', 'access_time', false),
											A4($author$project$Elm$Route$viewLink, '/cycling/distance', 'Distance', 'trending_flat', false)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route__divider')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route__group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route__group-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Swimming')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route__group-links')
										]),
									_List_fromArray(
										[
											A4($author$project$Elm$Route$viewLink, '/swimming/pace', 'Pace', 'timer', currentRoute === 8),
											A4($author$project$Elm$Route$viewLink, '/swimming/time', 'Time', 'access_time', currentRoute === 9),
											A4($author$project$Elm$Route$viewLink, '/swimming/distance', 'Distance', 'trending_flat', currentRoute === 10)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'slot', 'appContent'),
							$elm$html$Html$Attributes$class('route__wrapper')
						]),
					_List_fromArray(
						[
							A3(
							$elm$html$Html$node,
							'mwc-top-app-bar-fixed',
							_List_Nil,
							_List_fromArray(
								[
									A3(
									$elm$html$Html$node,
									'mwc-icon-button',
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'slot', 'navigationIcon'),
											A2($elm$html$Html$Attributes$attribute, 'icon', 'menu'),
											$elm$html$Html$Events$onClick(
											$author$project$Elm$Route$Self($author$project$Elm$Route$OpenNav))
										]),
									_List_Nil),
									_return ? A3(
									$elm$html$Html$node,
									'mwc-icon-button',
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'slot', 'navigationIcon'),
											A2($elm$html$Html$Attributes$attribute, 'icon', 'arrow_back'),
											$elm$html$Html$Events$onClick(
											A2($elm$core$Basics$composeL, $author$project$Elm$Route$Parent, $elm$core$Basics$identity)('/'))
										]),
									_List_Nil) : $elm$html$Html$text(''),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'slot', 'title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(title)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('content'),
									$elm$html$Html$Attributes$class('route__content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(' route__page'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('is-visible', visible)
												]))
										]),
									_List_fromArray(
										[pageHtml])),
									A2(
									$elm$html$Html$footer,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route__footer mdc-typography--body2')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Copyright © 2020 Alex Pryshchepa. All right reserved.'),
											$elm$html$Html$text(' '),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Icons made by'),
													$elm$html$Html$text(' '),
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$href('https://www.flaticon.com/authors/freepik'),
															A2($elm$html$Html$Attributes$attribute, 'title', 'Freepik'),
															A2($elm$html$Html$Attributes$attribute, 'rel', 'noreferrer'),
															$elm$html$Html$Attributes$target('_blank')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Freepik')
														])),
													$elm$html$Html$text(' '),
													$elm$html$Html$text('from'),
													$elm$html$Html$text(' '),
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$href('https://www.flaticon.com/'),
															A2($elm$html$Html$Attributes$attribute, 'title', 'Flaticon'),
															A2($elm$html$Html$Attributes$attribute, 'rel', 'noreferrer'),
															$elm$html$Html$Attributes$target('_blank')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('www.flaticon.com')
														]))
												]))
										])),
									A3(
									$elm$html$Html$node,
									'custom-mwc-snackbar',
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'timeoutMs', '4000'),
											A2($elm$html$Html$Attributes$attribute, 'open', snackbar.a),
											A2($elm$html$Html$Attributes$attribute, 'labeltext', snackbar.b),
											A2(
											$elm$html$Html$Events$on,
											'MDCSnackbar:closed',
											$elm$json$Json$Decode$succeed(
												$author$project$Elm$Route$Self($author$project$Elm$Route$HideSnackbar)))
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Elm$Route$view = function (model) {
	var wrapper = $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('route mdc-typography')
			]));
	var _v0 = model.d;
	switch (_v0.$) {
		case 0:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$NotFoundMsg),
							$author$project$Elm$Page$NotFound$view(subModel))
						])),
				bx: 'Speed Matters - 404'
			};
		case 1:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Speed Matters',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$HomeMsg),
								$author$project$Elm$Page$Home$view(subModel)))
						])),
				bx: 'Speed Matters - Home'
			};
		case 2:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Running pace',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningPaceMsg),
								$author$project$Elm$Page$RunningPace$view(subModel)))
						])),
				bx: 'Speed Matters - Running'
			};
		case 3:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Running time',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningTimeMsg),
								$author$project$Elm$Page$RunningTime$view(subModel)))
						])),
				bx: 'Speed Matters - Running'
			};
		case 4:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Running distance',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$RunningDistanceMsg),
								$author$project$Elm$Page$RunningDistance$view(subModel)))
						])),
				bx: 'Speed Matters - Running'
			};
		case 5:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Cycling speed',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingSpeedMsg),
								$author$project$Elm$Page$CyclingSpeed$view(subModel)))
						])),
				bx: 'Speed Matters - Cycling'
			};
		case 6:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Cycling time',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingTimeMsg),
								$author$project$Elm$Page$CyclingTime$view(subModel)))
						])),
				bx: 'Speed Matters - Cycling'
			};
		case 7:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Cycling distance',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$CyclingDistanceMsg),
								$author$project$Elm$Page$CyclingDistance$view(subModel)))
						])),
				bx: 'Speed Matters - Cycling'
			};
		case 8:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Swimming pace',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingPaceMsg),
								$author$project$Elm$Page$SwimmingPace$view(subModel)))
						])),
				bx: 'Speed Matters - Swimming'
			};
		case 9:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Swimming time',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingTimeMsg),
								$author$project$Elm$Page$SwimmingTime$view(subModel)))
						])),
				bx: 'Speed Matters - Swimming'
			};
		case 10:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Swimming distance',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$SwimmingDistanceMsg),
								$author$project$Elm$Page$SwimmingDistance$view(subModel)))
						])),
				bx: 'Speed Matters - Swimming'
			};
		default:
			var subModel = _v0.a;
			return {
				u: wrapper(
					_List_fromArray(
						[
							A3(
							$author$project$Elm$Route$viewNav,
							model,
							'Contact',
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeL, $author$project$Elm$Route$Self, $author$project$Elm$Route$ContactMsg),
								$author$project$Elm$Page$Contact$view(subModel)))
						])),
				bx: 'Speed Matters - Contact'
			};
	}
};
var $author$project$Main$view = function (model) {
	var _v0 = $author$project$Elm$Route$view(model.N);
	var title = _v0.bx;
	var body = _v0.u;
	return {
		u: _List_fromArray(
			[
				A2($elm$html$Html$map, $author$project$Main$RouteMsg, body)
			]),
		bx: title
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{bZ: $author$project$Main$init, cd: $author$project$Main$UrlChanged, ce: $author$project$Main$LinkClicked, cp: $author$project$Main$subscriptions, ct: $author$project$Main$update, cu: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(0))(0)}});}(this));